import React, { useState, useContext, useCallback } from 'react'
import Navbar from '../Tryitnow/Navbar';
import ThemeContext from './ThemeContext';
import { FaApple } from "react-icons/fa6";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import Footer from './Footer';
import google from '../assets/Images/google.png'
import facebook from '../assets/Images/facebook-login.png'
import { Link } from 'react-router-dom';
import { LoginSocialGoogle } from 'reactjs-social-login';



const Login = () => {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError('Please fill in all fields');
            return;
        }
        setError('');
        // Submit login form logic here
    };

    // const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className={`h-[80vh] ${isDarkMode ? "bg-[#09090B] text-white login-bgg" : "bg-white text-black login-bgggg"} `} >
                <div className="login-bggg ">
                    <Navbar />
                    <div className="container mx-auto flex justify-center place-items-center mt-14 lg::mt-24">
                        <div className="glass rounded-2xl p-4 md:p-8 w-10/12 lg:w-1/2 ">
                            <h2 className="text-2xl font-bold mb-6">Sign in</h2>
                            {error && <div className="text-red-500 mb-4">{error}</div>}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-400 text-sm font-bold mb-2">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="shadow border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline glass "
                                        required
                                        placeholder='example@email.com'
                                    />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="password" className="block text-gray-400 text-sm font-bold mb-2">
                                        Password
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="shadow glass border rounded w-full py-2 px-3 focus:outline-none focus:shadow-outline"
                                            required
                                            placeholder="Enter Your Password"
                                        />
                                        {/* Toggle button */}
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="absolute right-3 top-1/4 transform -translate-y-1/2 bg-transparent focus:outline-none hvr-grow"
                                        >
                                            {showPassword ? (
                                                <IoIosEye className="text-xl" />
                                            ) : (
                                                <IoIosEyeOff className="text-xl" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mb-4">
                                    <a href="#" className=" focus:outline-none focus:underline">
                                        Forgot password?
                                    </a>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to='/pricing' className='w-full'>
                                        <button
                                            type="submit"
                                            className={` all-btn font-bold py-2 px-4 rounded focus:outline-none w-full ${!email || !password ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            disabled={!email || !password}
                                        >
                                            Sign in
                                        </button>
                                    </Link>
                                </div>
                                <div className='text-center mt-2'>
                                    Don't have an account? <Link to='/signup' className=' border-b'>Signup</Link>
                                </div>
                                <div className="text-center mt-4 text-gray-400">
                                    Or
                                </div>
                                <div className="flex justify-center mt-4 gap-6">
                                <LoginSocialGoogle
                                        client_id="905543553860-676ntf0rjj1ufqakoitnjchaj4nh6ugo.apps.googleusercontent.com"
                                        redirect_uri={window.location.origin + "/pricing"}  // Redirect to pricing page
                                        access_type="offline"
                                        onResolve={({ provider, data }) => {
                                            console.log("Login successful with provider:", provider);
                                            console.log("User data:", data);
                                            window.location.href = "/pricing";  // Redirect to the pricing page after login
                                        }}
                                        onReject={error => {
                                            console.error("Login failed:", error);
                                            alert("Google login failed. Please try again.");
                                        }}
                                    >
                                        <button
                                            aria-label="Sign in with Google"
                                            className="bg-white rounded-full py-2 px-2 font-bold mr-2 focus:outline-none focus:shadow-outline hover:scale-105 transition-transform duration-200"
                                        >
                                            <img src={google} alt="Google logo" className="w-5 h-5" />
                                        </button>
                                    </LoginSocialGoogle>

                                    <button aria-label="Sign in with Apple" className="bg-white rounded-full py-2 px-2 font-bold  mr-2 focus:outline-none focus-shadow-outline hvr-grow">
                                        <FaApple className='text-black rounded-full   text-2xl' />
                                    </button>
                                    <button aria-label="Sign in with Facebook" className="bg-white rounded-full py-2 px-2 text-white font-bold  focus:outline-none focus-shadow-outline hvr-grow">
                                        {/* <FaFacebook  className='text-black  rounded-full   text-2xl'/> */}
                                        <img src={facebook} alt="" />
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Login
