import React, { useState , useContext } from "react";
import ThemeContext from './ThemeContext';

const Faqs = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    
    const { isDarkMode } = useContext(ThemeContext); 


    const faqs = [
        {
            question: "1. What is Genius?",
            answer: "Genius is a platform that offers a suite of advanced Al tools designed to enhance productivity and creativity. Our tools include sentence rewriting, code generation, custom templates, Al video production, and Al image creation.",
        },
        {
            question: "2. How do I get started with Genius?",
            answer: "Genius is a platform that offers a suite of advanced Al tools designed to enhance productivity and creativity. Our tools include sentence rewriting, code generation, custom templates, Al video production, and Al image creation.",
        },
        {
            question: "3. Is Genius suitable for beginners?",
            answer: "Genius is a platform that offers a suite of advanced Al tools designed to enhance productivity and creativity. Our tools include sentence rewriting, code generation, custom templates, Al video production, and Al image creation.",
        },
        {
            question: "4. What types of tasks can Genius help with?",
            answer: "Genius is a platform that offers a suite of advanced Al tools designed to enhance productivity and creativity. Our tools include sentence rewriting, code generation, custom templates, Al video production, and Al image creation.",
        },
        {
            question: "5. Can Genius help me with coding?",
            answer: "Genius is a platform that offers a suite of advanced Al tools designed to enhance productivity and creativity. Our tools include sentence rewriting, code generation, custom templates, Al video production, and Al image creation.",
        },
        {
            question: "6. How does the Al video tool work",
            answer: "Genius is a platform that offers a suite of advanced Al tools designed to enhance productivity and creativity. Our tools include sentence rewriting, code generation, custom templates, Al video production, and Al image creation.",
        },

    ];

    return (
        <>
            <div className={`container-fluid md:px-16 p-6 ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff]'  : 'bg-white text-black border-[black] ' }`}>
                <div className="xl:flex">
                    <div className='xl:w-1/2'>
                        <div className='lg:w-6/12'>
                            <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-4 font-color">Faqs</h2>
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4 py-5">Discover the Benefits of Genius</h1>
                        </div>
                        <p className="text-[#A9A9A9] mb-6">This FAQ section provides clear and concise answers to common questions, helping potential users understand how to use Genius and the benefits it offers.</p>
                    </div>

                    <div className=" xl:w-1/2">
                        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold text-center mb-8 ">Frequently Asked Questions</h2>
                        <div className="space-y-4">
                            {faqs.map((faq, index) => (
                                <div
                                    key={index}
                                    className="border-b border-gray-300 p-4 cursor-pointer "
                                    onClick={() => toggleFAQ(index)}
                                >
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-lg font-semibold">{faq.question}</h3>
                                        <span className="text-xl">
                                            {openIndex === index ? "-" : "+"}
                                        </span>
                                    </div>
                                    {openIndex === index && (
                                        <p className="mt-4 ">{faq.answer}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faqs
