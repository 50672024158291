import React, { useContext, useState } from 'react';
import ThemeContext from './ThemeContext';
import Navbar from '../Tryitnow/Navbar';
import Footer from './Footer';
import { FaCheck } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Pricing = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [activeTab, setActiveTab] = useState('monthly');
    const [hoveredCard, setHoveredCard] = useState(null); // Initialize hover state

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const monthlyPlans = [
        {
            price: 15,
            title: 'Basic',
            description: 'For small teams or individuals just getting started.',
            features: [
                'Multi-step Zaps',
                '3 Premium Apps',
                '2 Users team',
            ],
            className: 'pricing',
        },
        {
            price: 20,
            title: 'Standard',
            description: 'For growing teams looking for more automation features.',
            features: [
                'Multi-step Zaps',
                'Unlimited Premium Apps',
                '50 Users team',
                'Shared Workspace',
            ],
            className: 'pricing',
        },
        {
            price: 25,
            title: 'Premium',
            description: 'For large teams or organizations.',
            features: [
                'Multi-step Zap',
                'Unlimited Premium Apps',
                'Unlimited Users Team',
                'Advanced Admin',
                'Custom Data Retention',
            ],
             bg:'#2D218E',
             className: 'text-white popular-allside lg:top-[-1rem] w-[320px] h-[516px]',
            isPopular: true, // Add this flag to indicate the popular plan
        }
    ];

    const yearlyPlans = [
        {
            price: 150,
            title: 'Basic',
            description: 'For small teams or individuals just getting started.',
            features: [
                'Multi-step Zaps',
                '3 Premium Apps',
                '2 Users team',
            ],
            className: 'pricing',
        },
        {
            price: 200,
            title: 'Standard',
            description: 'For growing teams looking for more automation features.',
            features: [
                'Multi-step Zaps',
                'Unlimited Premium Apps',
                '50 Users team',
                'Shared Workspace',
            ],
            className: 'pricing',
        },
        {
            price: 250,
            title: 'Premium',
            description: 'For large teams or organizations.',
            features: [
                'Multi-step Zap',
                'Unlimited Premium Apps',
                'Unlimited Users Team',
                'Advanced Admin',
                'Custom Data Retention',
            ],
            bg:'#2D218E',
            className: 'text-white popular-allside  lg:top-[-1rem] w-[320px] h-[516px]',
            isPopular: true,
        }
    ];

    return (
        <>
            <div
                className={` ${isDarkMode
                    ? "bg-[#09090B] text-white border-[#ffffff] policy-bgg"
                    : "bg-white text-black  policy-bgggg"
                    }`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 policy-bggg">
                    <div className="text-center">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">
                            Plans & Pricing
                        </h1>
                        <p className="text-lg mb-8">
                            Whether your time-saving automation needs are large or small, we’re here to help you scale.
                        </p>
                    </div>

                    {/* Toggle Tabs */}
                    <div className="flex mt-5 justify-center">
                        <div className="relative flex md:w-1/3 w-full 2xl:w-1/5 glass rounded-full p-2">
                            {/* Sliding Indicator */}
                            <div
                                className={`absolute top-0 left-0 h-full w-1/2 rounded-full transition-all duration-300 ease-in-out  ${activeTab === "monthly" ? "all-btn" : "translate-x-full all-btn"
                                    }`}
                            ></div>

                            {/* Monthly Button */}
                            <button
                                className={`z-10 w-1/2 text-center font-bold rounded-full text-sm lg:text-xl transition-all ${activeTab === "monthly" ? "" : ""
                                    }`}
                                onClick={() => handleTabClick("monthly")}
                            >
                                MONTHLY
                            </button>

                            {/* Yearly Button */}
                            <button
                                className={`z-10 w-1/2 text-center font-bold rounded-full text-sm lg:text-xl transition-all ${activeTab === "yearly" ? "" : ""
                                    }`}
                                onClick={() => handleTabClick("yearly")}
                            >
                                YEARLY
                            </button>
                        </div>
                    </div>

                    {/* Monthly Plan */}
                    {activeTab === "monthly" && (
                        <div className="glass w-fit mx-auto mt-5 px-4 py-4 md:py-10 md:px-10 rounded-3xl transition-opacity duration-300 ease-in-out">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                                {/* Plan Card */}
                                {monthlyPlans.map((plan, index) => (
                                    <div
                                        key={index}
                                        className={` bg-[#2D218E] hvr-float rounded-2xl shadow-lg p-8 w-[320px] h-[500px] transition-all duration-300 ${hoveredCard === index
                                                ? "  hover:shadow-xl "
                                                : ""
                                            } ${plan.className}`}
                                        style={{  backgroundColor: plan.bg || 'transparent',
                                        boxShadow: '0 4px 15px rgba(236, 117, 228, 0.15)' }}
                                        onMouseEnter={() => setHoveredCard(index)} // Set hovered card index
                                        onMouseLeave={() => setHoveredCard(null)} // Reset hover state
                                    >
                                        {plan.isPopular && (
                                            <div className="absolute top-2 right-2 glass text-[#f976a5] py-1 px-3 text-xs md:text-sm font-semibold rounded-full">
                                                Most Popular
                                            </div>
                                        )}
                                        <div className="flex flex-col justify-between h-full space-y-4 mt-5 pb-5">
                                            <div className="flex justify-between items-center">
                                                <span className="text-4xl font-bold flex place-items-center gap-2">
                                                    ${plan.price}
                                                    <p className="md:text-xl text-lg">/month</p>
                                                </span>
                                            </div>
                                            <div className="space-y-4 ">
                                                <h3 className="md:text-3xl text-lg font-bold">{plan.title}</h3>
                                                <p className="mt-4 md:text-lg text-sm">{plan.description}</p>
                                                <ul className="text-left">
                                                    {plan.features.map((feature, i) => (
                                                        <li key={i} className="md:text-lg text-sm flex gap-4 place-items-center">
                                                            <div className="check-bg p-1">
                                                                <FaCheck className="text-[#BB6BD9] text-xs" />
                                                            </div>
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                           <Link to='/dashboard'>
                                           <button
                                                className={`w-full font-bold py-2 px-4 cursor-pointer rounded-full focus:outline-none focus:shadow-outline ${hoveredCard === index ? "all-btn" : "glass"
                                                    }`}
                                                    style={{ cursor: 'pointer' }} 
                                            >
                                                Choose plan
                                            </button>
                                           </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Yearly Plan */}

                    {activeTab === "yearly" && (
                        <div className="glass w-fit mx-auto mt-5 px-4 py-4 md:py-10 md:px-10 rounded-3xl transition-opacity duration-300 ease-in-out">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                                {/* Plan Card */}
                                {yearlyPlans.map((plan, index) => (
                                    <div
                                        key={index}
                                        className={` bg-[#2D218E] hvr-float rounded-2xl shadow-lg p-8 w-[320px] h-[500px] transition-all duration-300 ${hoveredCard === index
                                                ? "  hover:shadow-xl "
                                                : ""
                                            } ${plan.className}`}
                                        style={{  backgroundColor: plan.bg || 'transparent',
                                        boxShadow: '0 4px 15px rgba(236, 117, 228, 0.15)' }}
                                        onMouseEnter={() => setHoveredCard(index)} // Set hovered card index
                                        onMouseLeave={() => setHoveredCard(null)} // Reset hover state
                                    >
                                        {plan.isPopular && (
                                            <div className="absolute top-2 right-2 glass text-[#f976a5] py-1 px-3 text-xs md:text-sm font-semibold rounded-full">
                                                Most Popular
                                            </div>
                                        )}
                                        <div className="flex flex-col justify-between h-full space-y-4 mt-5 pb-5">
                                            <div className="flex justify-between items-center">
                                                <span className="text-4xl font-bold flex place-items-center gap-2">
                                                    ${plan.price}
                                                    <p className="md:text-xl text-lg">/month</p>
                                                </span>
                                            </div>
                                            <div className="space-y-4 ">
                                                <h3 className="md:text-3xl text-lg font-bold">{plan.title}</h3>
                                                <p className="mt-4 md:text-lg text-sm">{plan.description}</p>
                                                <ul className="text-left">
                                                    {plan.features.map((feature, i) => (
                                                        <li key={i} className="md:text-lg text-sm flex gap-4 place-items-center">
                                                            <div className="check-bg p-1">
                                                                <FaCheck className="text-[#BB6BD9] text-xs" />
                                                            </div>
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <Link to='/dashboard'>
                                           <button
                                                className={`w-full font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline ${hoveredCard === index ? "all-btn" : "glass"
                                                    }`}
                                            >
                                                Choose plan
                                            </button>
                                           </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Pricing;
