import React, { useState} from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { MdOutlineChatBubbleOutline } from 'react-icons/md';
import { AiOutlineSetting } from 'react-icons/ai';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/Images/chat-bot.png';
import pro from '../assets/Images/proimg.png';
import { IoArrowForwardOutline } from "react-icons/io5";
import { GrNotes } from 'react-icons/gr';
import icon from '../assets/Images/icon.png';

const Sidebar = ({ isOpen}) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const location = useLocation();

    // Toggle dropdowns for the sidebar
    const toggleDropdown = (dropdown) => {
        // If sidebar is closed, don't allow dropdowns to open
        if (!isOpen) return;

        // Toggle dropdown on click
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    const navLinks = [
        { to: '/dashboard/home', icon: <MdOutlineChatBubbleOutline className="text-2xl" />, label: 'Chat' },
        { to: '/dashboard/aipersonalities', icon: <FaUserFriends className="text-2xl" />, label: 'AI Personalities' }
    ];

    const dropdowns = [
        {
            label: 'Chat History',
            icon: <GrNotes className="text-2xl" />,
            items: [
                { name: 'History', link: '/dashboard/history' },
                { name: 'Billing', link: '/billing' },
                { name: 'Invoice', link: '/invoice' }
            ]
        },
        {
            label: 'Settings',
            icon: <AiOutlineSetting className="text-2xl" />,
            items: [
                { name: 'Tone', link: '/profile' },
                { name: 'Language', link: '/preferences' },
                { name: 'Formality Level', link: '/security' }
            ]
        }
    ];

    return (
        <div className="h-full">
            {/* Sidebar */}
            <div className={` transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'} text-white h-[100vh]`}>
                {/* Logo */}
                <div className={`flex items-center  px-4 py-2 ${!isOpen ? 'justify-center' : 'justify-center'} relative`}>
                    <Link to='/dashboard' className='flex gap-4 justify-center place-items-center'>
                        <img src={logo} alt="Logo" className={`transition-all duration-300 ${!isOpen ? 'w-10' : 'w-12'}`} />
                        <span className={`transition-all duration-300 font-color text-3xl font-bold mt-2 ${!isOpen ? 'hidden' : 'block'}`}>Dashboard</span>
                    </Link>
                </div>

                {/* Navigation Links */}
                <nav className="flex flex-col p-4 space-y-4">
                    {navLinks.map(({ to, icon, label }) => (
                        <NavLink
                            to={to}
                            key={label}
                            className={`text-bg p-2 rounded flex items-center gap-4 ${!isOpen ? 'justify-center' : ''}`}
                            style={({ isActive }) => ({ color: isActive ? '' : '' })}
                        >
                            {icon}
                            <span className={`transition-all duration-300 ${!isOpen ? 'hidden' : 'block'}`}>{label}</span>
                        </NavLink>
                    ))}

                    {/* Dropdown Menus */}
                    {dropdowns.map(({ label, icon, items }) => (
                        <div key={label}>
                            <button
                                type="button"
                                onClick={() => toggleDropdown(label)}
                                className="flex items-center w-full p-2 text-base transition duration-75 rounded-lg text-bg justify-center gap-4"
                                aria-controls={`dropdown-${label.toLowerCase().replace(' ', '-')}`}
                                aria-expanded={openDropdown === label}
                            >
                                {icon}
                                <span className={`flex-1 text-left whitespace-nowrap transition-all duration-300 ${!isOpen ? 'hidden' : 'block'}`}>
                                    {label}
                                </span>
                                {isOpen && (
                                    <svg className={`w-3 h-3 transition-transform duration-200 ${openDropdown === label ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                )}
                            </button>
                            {isOpen && openDropdown === label && (
                                <ul id={`dropdown-${label.toLowerCase().replace(' ', '-')}`} className="py-2 space-y-2">
                                    {items.map(item => (
                                        <li key={item.name}>
                                            <NavLink
                                                to={item.link}
                                                className="flex items-center w-full p-2 transition duration-75 rounded-lg pl-11 font-hover"
                                            >
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </nav>

                {/* Bottom Upgrade Section */}
                <div className="absolute bottom-4 ">
                    {!isOpen ? (
                        <div className="bg-white rounded-lg flex justify-center ms-8">
                            <img src={pro} alt="" className='w-10 h-10' />
                        </div>
                    ) : (
                        <div className="bg-pro rounded-lg shadow-lg p-10 text-white text-center">
                            <div className="flex justify-center items-center mb-4">
                                <div className="bg-white rounded-lg">
                                    <img src={pro} alt="" className='w-16 h-16' />
                                </div>
                            </div>
                            <h2 className="text-2xl font-bold mb-2">Upgrade to Pro</h2>
                            <p className="text-lg mb-4">Unlock powerful features with our pro upgrade today!</p>
                            <div className='flex flex-col justify-center'>
                            <button className="bg-white text-[#EC75E4] font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline flex gap-4 place-items-center justify-center">
                                Upgrade now
                                <IoArrowForwardOutline className='text-2xl'/>

                            </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
