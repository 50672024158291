import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Dashboard/Component/Home';
import Sidebar from './Sidebar';
import Navbar from '../Dashboard/Component/Navbar';
import Chathistory from './Component/Chathistory';
import AIpersonalities from './Component/AIpersonalities';

const Dashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="w-full flex h-full">
            {/* Sidebar Section */}
            <div className={` glass7 transition-all duration-300 ${isOpen ? 'w-1/5' : 'w-28'}`}>
                <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            </div>

            {/* Main Content Section */}
            <div className="flex flex-col flex-grow w-full">
                <Navbar isExpanded={isExpanded} toggleMenu={toggleMenu}  isOpen={isOpen}  toggleSidebar={toggleSidebar}/>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/history" element={<Chathistory />} />
                    <Route path="/aipersonalities" element={<AIpersonalities />} />
                </Routes>
            </div>
        </div>
    );
};

export default Dashboard;
