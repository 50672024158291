import React, { useContext } from 'react';
import ThemeContext from './ThemeContext';
import Navbar from '../Tryitnow/Navbar';
import Footer from './Footer';
import img1 from '../assets/Images/aboutus-1.png';
import img2 from '../assets/Images/aboutus-2.png';
import img3 from '../assets/Images/aboutus-3.png';
import img4 from '../assets/Images/aboutus-4.png';

const Aboutusnav = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const aboutUsData = [
        {
            heading: "What is the Genius Chatbot?",
            text: "The Genius Chatbot is an advanced conversational AI assistant developed to offer human-like interactions and solve queries instantly. Built on cutting-edge AI technology, it can handle everything from answering FAQs and troubleshooting technical issues to assisting with order tracking and providing personalized recommendations.",
            image: img1,
            order: "textfirst", 
        },
        {
            heading: "Our Mission",
            text: "At Genius, our mission is to simplify your experience by providing instant, easy-to-access assistance. Whether you're looking for answers to common questions, need help navigating a website, or want real-time updates on your orders, Genius is here to help. We believe in making information and support available at your fingertips, any time of the day or night.",
            image: img2,
            order: "imageFirst",
        },
        {
            heading: "Our Mission",
            text: "We are committed to enhancing customer service and revolutionizing the way businesses interact with their users. With Genius, we aim to provide an intuitive, responsive, and intelligent chatbot experience that makes problem-solving easier than ever.",
            image: img3,
            order: "textfirst",
        },
        {
            heading: "Why Choose Genius?",
            text: "Instant Responses: With Genius, you don't have to wait for assistance. Our chatbot provides real-time, automated responses to your queries, saving you time and effort.",
            points: [
                "Instant Responses: With Genius, you don’t have to wait for assistance. Our chatbot provides real-time, automated responses to your queries, saving you time and effort.", "24/7 Availability: No matter when you need help, Genius is always online and ready to assist—24 hours a day, 7 days a week.",
                "Human-like Interaction: Our chatbot is designed to understand and respond naturally, creating a seamless and engaging user experience.",
                "Personalized Support: Genius learns from your interactions, offering tailored suggestions and solutions based on your preferences and past queries."
            ],
            image: img4,
            order: "imageFirst",
        },
    ];
    

    return (
        <>
            <div
                className={` ${isDarkMode
                    ? "bg-[#09090B] text-white border-[#ffffff] policy-bgg"
                    : "bg-white text-black border-[black] policy-bgggg"
                    }`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 policy-bggg">
                    <div className="">
                        <div className="glass rounded-3xl md:p-10 p-6">
                            <div className=" py-24">
                                <div className="container mx-auto px-4">
                                    <h1 className="text-4xl font-bold mb-8 text-center">About</h1>
                                    <p className='text-center'>Welcome to Genius, your intelligent and reliable chatbot solution designed to provide fast, efficient, and personalized support whenever you need it.</p>

                                    {/* Dynamic Sections */}
                                    {aboutUsData.map((section, index) => (
                                        <div className="mt-10" key={index}>
                                            <div className={`flex lg:flex-row flex-col ${section.order === "imageFirst" ? "lg:flex-row-reverse" : ""} justify-between items-center gap-10 lg:gap-20`}>
                                                <img src={section.image} alt={section.heading} className={`lg:w-[350px] h-auto mb-4 lg:mb-0 ${isDarkMode ? 'ridingshadow' : 'ridingshadow1'}`} />
                                                <div>
                                                    <h2 className="text-2xl font-bold mb-4">{section.heading}</h2>
                                                    {section.points ? (
                                                        <ul className="list-disc text-lg  pl-5">
                                                            {section.points.map((point, idx) => (
                                                                <li key={idx}>{point}</li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p className="text-lg ">{section.text}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Aboutusnav;
