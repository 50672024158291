import React, { createContext, useState, useEffect } from 'react';


import Home from '../Component/Home'
import Navbar from '../Component/Navbar';
import Aboutus from './Aboutus';
import Ourfeatures from './Ourfeatures';
import Howitwork from './Howitwork';
import Whychoose from './Whychoose';
import Testimonial from './Testimonial';
import Faqs from './Faqs';
import Contribute from './Contribute';
import Footer from './Footer';

const Landingpage = () => {
   
  return (
    <>
     
    {/* <Navbar/> */}
    <Home/>
    <Aboutus/>
    <Ourfeatures/>
    <Howitwork/>
    <Whychoose/>
    <Testimonial/>
    <Faqs/>
    <Contribute/>
    <Footer/>
    </>
  )
}

export default Landingpage
