import React, { useContext } from 'react'
import Footer from './Footer'
import Navbar from '../Tryitnow/Navbar'
import ThemeContext from './ThemeContext';

const Helpcenter = () => {
    const { isDarkMode } = useContext(ThemeContext);
    return (
        <>
            <div
                className={` ${isDarkMode
                    ? "bg-[#09090B] text-white border-[#ffffff] policy-bgg"
                    : "bg-white text-black border-[black] policy-bgggg"
                    }`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 policy-bggg">
                    <h1 className="text-6xl font-bold text-center mb-8">
                        Hi, how can we help?
                    </h1>
                    <p className="text-center mb-8">
                        Discover how to use Genius and find answers to your questions
                    </p>
                    {/* Search Bar */}
                    <div className="flex justify-center mb-8">
                        <input
                            type="text"
                            placeholder="Search Topic"
                            className="w-full md:w-1/4 px-4 py-2 rounded-s-xl  focus:outline-none glass"
                        />
                        <button className="all-btn w-1/2 lg:w-1/12 rounded-e-xl  focus:outline-none">
                            Search
                        </button>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 text-center">
                        <div className="glass rounded-lg xl:p-16 p-10 shadow-md flex-1">
                            <h2 className="text-xl font-bold mb-2">Get started</h2>
                            <p className="text-gray-400">
                                Here for the first time? Learn the basics of Genius. We'll show you
                                the ropes.
                            </p>
                        </div>
                        <div className="glass rounded-lg xl:p-16 p-10 shadow-md flex-1">
                            <h2 className="text-xl font-bold mb-2">Build your chatbot</h2>
                            <p className="text-gray-400">
                                Guidelines on how to create a powerful chatbot Story.
                            </p>
                        </div>
                        <div className="glass rounded-lg xl:p-16 p-10 shadow-md flex-1">
                            <h2 className="text-xl font-bold mb-2">Connect your chatbot</h2>
                            <p className="text-gray-400">
                                Integrate ChatBot with tools you're already using.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 mt-5 text-center">
                        <div className="glass rounded-lg xl:p-16 p-10 shadow-md flex-1">
                            <h2 className="text-xl font-bold mb-2">Manage data</h2>
                            <p className="text-gray-400">
                                Learn how to use ChatBot analytics and get the most out of it.
                            </p>
                        </div>
                        <div className="glass rounded-lg xl:p-16 p-10 shadow-md flex-1">
                            <h2 className="text-xl font-bold mb-2">Manage your account</h2>
                            <p className="text-gray-400">
                                Set up ChatBot and add teammates to your account.
                            </p>
                        </div>
                        <div className="glass rounded-lg xl:p-16 p-10 shadow-md flex-1">
                            <h2 className="text-xl font-bold mb-2">Advanced use</h2>
                            <p className="text-gray-400">
                                Add more advanced actions to your Stories with webhooks.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Helpcenter
