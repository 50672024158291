import React, { useContext, useState, useRef } from "react";
import ThemeContext from "../Component/ThemeContext";
import Navbar from "./Navbar";
import { BiSolidUpArrow } from "react-icons/bi";
import { Link } from "react-router-dom";

const Tryitnow = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const [activeTab, setActiveTab] = useState('step1');
    const [activeSubTab, setActiveSubTab] = useState('generative');

    // Function to handle main tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Function to handle sub-tab click
    const handleSubTabClick = (subTab) => {
        setActiveSubTab(subTab);
    };

    const textInputRef = useRef(null);

    // Function to handle the play button click
    const handlePlayClick = () => {
        if (textInputRef.current) {
            const text = textInputRef.current.value;
            const utterance = new SpeechSynthesisUtterance(text);
            speechSynthesis.speak(utterance);
        }
    };

    const [botSettings, setBotSettings] = useState({
        botName: "",
        description: "",
        websiteLink: "",
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setBotSettings((prevSettings) => ({
            ...prevSettings,
            [id]: value,
        }));
    };

    const [selectedVoice, setSelectedVoice] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const voices = [
        { name: 'Kendra', type: 'Long-form' },
        { name: 'Kimberly', type: 'Long-form' },
        { name: 'Matthew', type: 'Long-form' },
        { name: 'Salli', type: 'Long-form' },
        { name: 'Danielle', type: 'Long-form' },
        { name: 'Gregory', type: 'Long-form' },
        { name: 'Ruth', type: 'Long-form' },
        { name: 'Matthew', type: 'Generative' },
        { name: 'Ruth', type: 'Generative' },
    ];

    const filteredVoices = voices.filter(voice =>
        voice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const handleSubmit = () => {
        setActiveTab('step2'); // Change the active tab to 'step2'
    };
    const handleCancel = () => {
        setActiveTab('step1'); // Change the active tab to 'step2'
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value); // Use event.target.value for search input
    };

    const [value, setValue] = useState("0.40");
    // const [selectedVoice, setSelectedVoice] = useState(null); // Assuming selectedVoice is part of your state

    // Handle the input change and validate the value
    const handleChange = (event) => {
        let newValue = parseFloat(event.target.value);

        // Ensure the value stays within the min/max range
        if (newValue < 0.00) {
            newValue = 0.00;
        } else if (newValue > 1.00) {
            newValue = 1.00;
        }

        setValue(newValue.toFixed(2)); // Ensure two decimal places

        // You can customize selectedVoice here if needed, otherwise remove it if not in use
        setSelectedVoice(event.target.value); // or pass other relevant data
    };
    const [languageSections, setLanguageSections] = useState([1]);
    const addLanguageSection = () => {
        setLanguageSections([...languageSections, languageSections.length + 1]);
    };
    const deleteLanguageSection = (index) => {
        setLanguageSections(languageSections.filter((_, i) => i !== index));
    };


    return (
        <>
            <div className={` ${isDarkMode ? "bg-[#09090B] text-white " : "bg-white text-black "} `} >
                <Navbar />
                <div className="container mx-auto  p-4 ">
                    <div className="xl:flex gap-10">
                        <div>
                            <nav className="flex gap-3 mb-7">
                                <a href="#" className="">
                                    Genius
                                </a>
                                <span className="text-[#FFFFFF]">&gt;</span>
                                <a href="#" className="">
                                    Bot
                                </a>
                                <span className="">&gt;</span>
                                <span className="font-medium text-[#9D9D9D]">Create Bot</span>
                            </nav>

                            <div className={`grid grid-cols-1 md:w-1/2 xl:w-full  gap-4 glass rounded-xl p-4 mb-5 ${isDarkMode ? "glass" : "glass1 "}`}>
                                <div className=" mb-4">
                                    <button
                                        className={`text-2xl font-semibold  w-full broder border-b pb-6 text-start ${activeTab === 'step1' ? 'font-color ' : ''}`}
                                        onClick={() => setActiveTab('step1')}
                                    >
                                        <h2 className="text-2xl font-semibold mb-4">Step 1</h2>
                                        <p>Configure Bot Settings</p>
                                    </button>
                                    <button
                                        className={` text-2xl font-semibold rounded-lg w-full mt-5 text-start ${activeTab === 'step2' ? ' font-color' : ''}`}
                                        onClick={() => setActiveTab('step2')}
                                    >
                                        <h2 className="text-2xl font-semibold mb-4">Step 2</h2>
                                        <p>Add Languages</p>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {activeTab === 'step1' && (
                            <div className="xl:w-7/12 mb-5" >
                                <h1 className="text-3xl font-bold mb-4">
                                    Configure Bot Settings
                                </h1>

                                <div className={`${isDarkMode ? "bg-[#09090B] text-white try-bgg" : "bg-white text-black try-bgggg"} `} >
                                    <div className="try-bggg mb-5">
                                        <div className={`grid grid-cols-1  gap-4 glass rounded-xl w-full p-4 ${isDarkMode ? "glass" : "glass1 "}`} >
                                            <div className="">
                                                <div className="broder border-b pb-2">
                                                    <h2 className="text-2xl font-semibold mb-4 ">
                                                        Creation method
                                                    </h2>
                                                </div>

                                                <div>
                                                    {/* Tab Buttons */}
                                                    <div className="flex mt-5">
                                                        <button
                                                            className={` rounded-s-lg text-sm md:text-xl w-full md:w-1/4 py-2 ${activeSubTab === "traditional" ? "all-btn" : "glass"}`}
                                                            onClick={() => handleSubTabClick("traditional")}
                                                        >
                                                            Traditional
                                                        </button>
                                                        <button
                                                            className={` rounded-e-lg text-sm md:text-xl w-full md:w-1/4 py-2 ${activeSubTab === "generative" ? "all-btn" : "glass"}`}
                                                            onClick={() => handleSubTabClick("generative")}
                                                        >
                                                            Generative AI
                                                        </button>
                                                    </div>

                                                    {/* Conditional Rendering for Each Tab */}
                                                    {activeSubTab === "traditional" ? (
                                                        <div className="lg:flex space-y-4 lg:space-y-0 gap-4 mt-4">
                                                            <div className="p-4 border-allside w-full cursor-pointer">
                                                                <div className="flex place-items-start gap-4 ">
                                                                    <input
                                                                        id="bordered-radio-1"
                                                                        type="radio"
                                                                        name="botType"
                                                                        className="peer custom-radio hidden" // Use peer class
                                                                    />
                                                                    <label htmlFor="bordered-radio-1" className=" custom-radio-label1 2xl:h-[150px] xl:h-[245px]  lg:h-[160px] md:h-[130px]">
                                                                        <h3 className="text-lg font-bold">Create a blank bot</h3>
                                                                        <p className="mt-2">
                                                                            Create a basic bot with no preconfigured languages, intents, and slot types
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="p-4 border-allside w-full cursor-pointer">
                                                                <div className="flex place-items-start gap-4">
                                                                    <input
                                                                        id="bordered-radio-2"
                                                                        type="radio"
                                                                        name="botType"
                                                                        className="peer custom-radio hidden"
                                                                    />
                                                                    <label htmlFor="bordered-radio-2" className="custom-radio-label1 2xl:h-[150px] xl:h-[245px]  lg:h-[160px] md:h-[130px]">
                                                                        <h3 className="text-lg font-bold">Start with an example</h3>
                                                                        <p className="mt-2">
                                                                            An example bot has preconfigured languages, intents, and slot types. You can change these settings.
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="p-4 border-allside w-full cursor-pointer">
                                                                <div className="flex place-items-start gap-4">
                                                                    <input
                                                                        id="bordered-radio-3"
                                                                        type="radio"
                                                                        name="botType"
                                                                        className="peer custom-radio hidden"
                                                                    />
                                                                    <label htmlFor="bordered-radio-3" className="custom-radio-label1 2xl:h-[150px] xl:h-[245px]  lg:h-[160px] md:h-[130px]">
                                                                        <h3 className="text-lg font-bold">Start with transcripts</h3>
                                                                        <p className="mt-2">
                                                                            Automatically generate intents from conversation transcripts that you upload. Only English (US) language is available when starting with a transcript.
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="p-4 border-allside w-full lg:w-1/3 mt-4 cursor-pointer">
                                                            <div className="flex place-items-start gap-4">
                                                                <input
                                                                    id="bordered-radio-4"
                                                                    type="radio"
                                                                    name="botType"
                                                                    className="peer custom-radio hidden"
                                                                />
                                                                <label htmlFor="bordered-radio-4" className="custom-radio-label1 2xl:h-[150px] xl:h-[245px]  lg:h-[160px] md:h-[130px]">
                                                                    <h3 className="text-lg font-bold">Descriptive Bot Builder</h3>
                                                                    <p className="mt-2">
                                                                        Describe the type of bot you would like to create, and Lex will use generative AI to create intents and slot types for you.
                                                                    </p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                        <div className={` mt-5 rounded-lg ${isDarkMode ? "glass" : "glass1 "}`}>
                                            <div className="border-allside xl:flex gap-4 p-4 ">
                                                <p className="">You Must have Genius Bedrock set up in oreder to use this fetures. please ensure you have requested access to anthropic’s V2 model</p>
                                                <div className=" mt-2 md:w-1/4">
                                                    <button className={`hvr-grow justify-center w-full font-bold py-2 rounded-lg ${isDarkMode ? "glass" : "glass1 "}`}>Learn more</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className= {`mt-5 p-4 rounded-xl ${isDarkMode ? "glass" : "glass1 "}`}>
                                            <div className="broder border-b pb-2 mb-2">
                                                <h2 className="text-2xl font-semibold mb-4 ">
                                                    Bot Configuration
                                                </h2>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="botName" className="block  font-bold mb-2">
                                                    Bot Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="botName"
                                                    className=" border bg-transparent rounded w-full py-2 px-3 focus:outline-none focus:shadow-outline"
                                                    value={botSettings.botName}
                                                    onChange={handleInputChange}
                                                    maxLength={100}
                                                    placeholder="Booktrip or order flowers"
                                                />
                                                <p className="text-xs  mt-2">
                                                    Maximum 100 characters. Valid A-Za-z0-9,-,_.
                                                </p>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="description" className="block  font-bold mb-2">
                                                    Description (Optional)
                                                </label>
                                                <p className="mb-2 text-xs">This Description appears on bot list page. It can help you identify the purpose of your bot.</p>
                                                <textarea
                                                    id="description"
                                                    className=" border rounded w-full py-2 px-3 bg-transparent  focus:outline-none  focus:shadow-outline"
                                                    value={botSettings.description}
                                                    onChange={handleInputChange}
                                                    maxLength={200}
                                                    placeholder="IT Helpdesk bot for employees in the nort america offfice"
                                                />
                                                <p className="text-xs text-gray-400 mt-1">
                                                    Maximum 200 Character.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className={` ${isDarkMode
                                    ? "bg-[#09090B] text-white try-bgg"
                                    : "bg-white text-black try-bgggg"
                                    } `}>
                                    <div className="try-bggg">
                                        <div className={` p-4 rounded-xl ${isDarkMode ? "glass" : "glass1 "}`}>
                                            <div className="broder border-b pb-2 mb-2">
                                                <h2 className="text-2xl font-semibold mb-4 ">
                                                    Put Your Website
                                                </h2>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="botName" className="block  font-bold mb-2">
                                                    Website link
                                                </label>
                                                <input
                                                    type="text"
                                                    id="botName"
                                                    className=" border bg-transparent rounded w-full py-2 px-3   focus:outline-none focus:shadow-outline"
                                                    // value={}
                                                    onChange={handleInputChange}
                                                    // maxLength={100}
                                                    placeholder="Put hear"
                                                />
                                            </div>
                                        </div>
                                        <div className={`mt-5 p-4 rounded-xl ${isDarkMode ? "glass" : "glass1 "}`}>
                                            <div className="broder border-b pb-2 mb-2">
                                                <h2 className="text-2xl font-semibold mb-4 ">
                                                    Children's Online Privacy Protection Act (COPPA)
                                                </h2>
                                            </div>
                                            <div className="mb-4">
                                                <p>Is use of your bot subject to the Children's Online Privacy Protection Act (COPPA)</p>
                                                <div className="flex items-center me-4 my-2">
                                                    <input
                                                        id="green-radio-yes"
                                                        type="radio"
                                                        value="yes"
                                                        name="colored-radio"
                                                        className="hidden peer" // Hide the radio input
                                                    />
                                                    <label
                                                        htmlFor="green-radio-yes"
                                                        className="custom-radio-label"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="flex items-center me-4">
                                                    <input
                                                        id="green-radio-no"
                                                        type="radio"
                                                        value="no"
                                                        name="colored-radio"
                                                        className="hidden peer"
                                                    />
                                                    <label
                                                        htmlFor="green-radio-no"
                                                        className="custom-radio-label"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>


                                        </div>

                                        <div className={`mt-5 p-4 rounded-xl ${isDarkMode ? "glass" : "glass1 "}`}>
                                            <div className="broder border-b pb-2 mb-2">
                                                <h2 className="text-2xl font-semibold mb-4 ">
                                                    Idle session timeout
                                                </h2>
                                                <p> you can configure how long a session is maintained when the user does not provide any input and the session is idle. genius retains conttext information until session ends.</p>
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="text-xl font-semibold mb-4">Session timeout</h3>
                                                <div className="flex gap-4 mb-5">
                                                    <input type="text" defaultValue={5} className="bg-transparent glass rounded-lg py-2 px-2 w-full md:w-1/4" />
                                                    <select name="" id="" className="custom-select bg-transparent glass rounded-lg py-2 px-2 w-full md:w-1/4">
                                                        <option value="">Minutes(s)</option>
                                                        <option value="">Hours(s)</option>
                                                    </select>
                                                </div>
                                                <p className="text-xs">By Default Session Duration Is 5 Minutes.But You can Specify Any Duration Between 1 And 1440 Minutes ( 24 hours )</p>
                                            </div>
                                        </div>

                                        <div className="flex gap-8 justify-end mt-5">
                                            <Link to='/' className=" w-1/2  md:w-1/5 "><button className={`hvr-bounce-in justify-center w-full glass py-2 rounded-lg font-bold ${isDarkMode ? "glass" : "glass1 "}`}>cancel</button></Link>
                                            <button className="hvr-bounce-in w-1/2 md:w-1/5 py-2 rounded-lg all-btn justify-center text-black font-bold" onClick={handleSubmit}>Next</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        )}

                        {activeTab === 'step2' && (
                            <div className="xl:w-7/12">
                                <h1 className="text-3xl font-bold mb-4">Add Language to bot</h1>

                                <div className={`${isDarkMode ? 'bg-[#09090B] text-white try-bgg' : 'bg-white text-black try-bgggg'}`}>
                                    {languageSections.map((_, index) => (
                                        <div key={index} className="try-bggg">
                                            <div className="grid grid-cols-1 gap-4 glass rounded-xl w-full p-4 mt-5">
                                                <div>
                                                    <div className="broder border-b pb-2 flex justify-between ">
                                                        <h2 className="md:text-xl lg:text-2xl font-semibold mb-4 flex gap-4 place-items-center">
                                                            <BiSolidUpArrow className="rotate-180" />
                                                            Language Section {index + 1}
                                                        </h2>
                                                        {index !== 0 && (
                                                        <div className=" text-right">
                                                            <button
                                                                onClick={() => deleteLanguageSection(index)}
                                                                className="all-btn justify-center hvr-grow text-black font-bold px-4 py-2 rounded-lg"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    )}
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor={`language-${index}`} className="block font-bold mb-2">
                                                            Select language
                                                        </label>
                                                        <select name={`language-${index}`} id={`language-${index}`} className="custom-select bg-transparent glass rounded-lg py-2 px-2 w-full">
                                                            <option value="">English (US)</option>
                                                            <option value="">Arabic (AE)</option>
                                                            <option value="">Cantonese (HK)</option>
                                                            <option value="">Catalan (ES)</option>
                                                            <option value="">Dutch (NL)</option>
                                                            <option value="">English (AU)</option>
                                                            <option value="">English (GB)</option>
                                                            <option value="">English (IN)</option>
                                                            <option value="">English (US)</option>
                                                            <option value="">English (ZA)</option>
                                                            <option value="">Finnish (FI)</option>
                                                            <option value="">French (CA)</option>
                                                            <option value="">French (FR)</option>
                                                            <option value="">German (AT)</option>
                                                            <option value="">German (DE)</option>
                                                            <option value="">Hindi (IN)</option>
                                                            <option value="">Italian (IT)</option>
                                                            <option value="">Japanese (JP)</option>
                                                            {/* Add other options here */}
                                                        </select>
                                                    </div>

                                                    <div className="mb-4 mt-5">
                                                        <label htmlFor={`description-${index}`} className="block font-bold mb-2">
                                                            Description (Optional)
                                                        </label>
                                                        <textarea
                                                            id={`description-${index}`}
                                                            className="border rounded w-full py-2 px-3 bg-transparent focus:outline-none focus:shadow-outline resize-none"
                                                            value={botSettings.description}
                                                            onChange={handleInputChange}
                                                            maxLength={200}
                                                            rows={6}
                                                        />
                                                        <p className="text-xs text-gray-400 mt-1">Maximum 200 Character.</p>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor={`voiceInteraction-${index}`} className="block font-bold mb-2">
                                                            Voice interaction
                                                        </label>
                                                        <select name={`voiceInteraction-${index}`} id={`voiceInteraction-${index}`} className="custom-select bg-transparent glass rounded-lg py-2 px-2 w-full">
                                                            <option value="">Minutes(s)</option>
                                                            <option value="">Hours(s)</option>
                                                        </select>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor={`voiceSample-${index}`} className="block font-bold mb-2">
                                                            Voice Sample
                                                        </label>
                                                        <div className="flex md:gap-4">
                                                            <input
                                                                type="text"
                                                                className="mr-2 bg-transparent glass w-full py-2 px-2 rounded-lg"
                                                                defaultValue="Hello, my name is Genius. Let me know how I can assist you"
                                                                ref={textInputRef}
                                                            />
                                                            <button className="hvr-grow justify-center w-1/2 md:1/5 py-2 rounded-lg all-btn text-black font-bold" onClick={handlePlayClick}>
                                                                Play
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="mt-5">
                                                        <label htmlFor={`voiceIntensity-${index}`} className="block font-bold mb-2">
                                                            Voice Intensity
                                                        </label>
                                                        <div className="flex gap-4">
                                                            <input
                                                                type="number"
                                                                id={`voiceIntensity-${index}`}
                                                                className="mr-2 bg-transparent glass w-full py-2 px-2 rounded-lg"
                                                                defaultValue="0.40"
                                                                min="0.00"
                                                                max="1.00"
                                                                step="0.01"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <p className="text-xs text-gray-400 mt-1">Min 0.00, Max 1.00.</p>
                                                    </div>

                                                    {/* Conditionally render delete button */}
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="lg:flex gap-8 space-y-4 lg:space-y-0 justify-end mt-5">
                                        <button className={`w-full xl:w-1/5 py-2 rounded-lg hvr-bounce-in justify-center ${isDarkMode ? "glass" : "glass1 "}`} onClick={handleCancel}>Cancel</button>
                                        <button className={`w-full xl:w-1/4 py-2 rounded-lg hvr-bounce-in justify-center ${isDarkMode ? "glass" : "glass1 "}`}  onClick={addLanguageSection}>Add another language</button>
                                        <Link to='/signup' className="w-full xl:w-1/5">
                                            <button className="w-full mt-4 lg:mt-0 py-2 rounded-lg all-btn text-black font-bold hvr-bounce-in justify-center">Next</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tryitnow;
