import React, { useRef, useState, useContext } from 'react';
import testimonial1 from '../assets/Images/Whychoose1.png';
import testimonial2 from '../assets/Images/Whychoose2.png';
import testimonial3 from '../assets/Images/Whychoose3.png';
import testimonial4 from '../assets/Images/Whychoose4.png';
import ThemeContext from './ThemeContext';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const Testimonial = () => {

    const { isDarkMode } = useContext(ThemeContext);

    const testimonials = [
        {
            name: "Maria Garcia",
            company: "Innovatech Solutions",
            review: "The AI chatbot exceeded my expectations! It's incredibly intuitive, understands context well, and provides responses that are both accurate and engaging. It’s like having a knowledgeable assistant at your fingertips 24/7. Highly recommended!",
            image: testimonial1,
            rating: 5
        },
        {
            name: "David Smith",
            company: "NexGen Systems",
            review: "Overall, the AI chatbot is fantastic. It handles most queries with ease and offers helpful information quickly. My only issue is that it occasionally misunderstands complex questions, but it's still a great tool for everyday use.",
            image: testimonial2,
            rating: 5
        },
        {
            name: "Mia Williams",
            company: "Quantum Dynamics",
            review: "This chatbot has been a game-changer for our customer service team. It efficiently manages routine inquiries and frees up human agents for more complex issues. The integration was smooth, and the performance has been stellar",
            image: testimonial3,
            rating: 5
        },
        {
            name: "Charlotte Brown",
            company: "TechnoVista",
            review: "I’m thrilled with the user experience of this chatbot. It’s well-designed, user-friendly, and adapts to my needs seamlessly. The smooth interactions and quick responses make it an essential tool for both work and personal use",
            image: testimonial4,
            rating: 5
        },
        {
            name: "Emma Jones",
            company: "CodeWave Technologies",
            review: "I’m impressed with the accuracy of this AI chatbot. It handles technical questions and specific queries with remarkable precision. It has become an indispensable tool for my business, and I’ve seen a noticeable increase in productivity",
            image: testimonial4,
            rating: 5
        },
        {
            name: "Isabella Garcia",
            company: "PixelCraft Studios",
            review: "The user experience with this chatbot is excellent. It’s easy to interact with and provides relevant information quickly. The only downside is occasional lag during peak times, but overall, it’s a very effective tool.",
            image: testimonial3,
            rating: 5
        },
        {
            name: "Benjamin Johnson",
            company: "Artisan Visions",
            review: "This AI chatbot offers an exceptional user experience. It’s easy to set up and even easier to use. The conversational flow feels natural, and the chatbot understands my queries with minimal effort. It’s a top-notch tool that enhances productivity",
            image: testimonial2,
            rating: 5
        },
        {
            name: "Amelia Martinez",
            company: "Creative Pulse Agency",
            review: "The user experience with this AI chatbot is outstanding. It’s incredibly intuitive and easy to interact with, making it a pleasure to use. The interface is clean and responsive, and the chatbot’s ability to understand and address queries efficiently is impressive.",
            image: testimonial1,
            rating: 5
        },
        // Add more testimonials here as needed
    ];

    return (
        <div className={`bg-[#09090B]  py-20 md:px-16 px-6 ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff] features-bgg' : 'bg-white text-black border-[black]  features-bggg'}`}>
            <div className='features-bg'>
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold mb-4">Hear from Our Happy Customers</h1>
                    <p className="text-lg">What our customers say about us</p>
                </div>
                {/* Grid layout for large screens */}
                <div className="hidden xl:grid grid-cols-4 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className={` p-8 rounded-lg shadow-lg flex flex-col  hvr-grow pricing ${isDarkMode ? 'glass'  : ' glass1'}`}>
                            <div className="flex gap-4 place-items-center">
                                <img src={testimonial.image} alt="Customer Image" className="w-20 h-20 rounded-full mb-4 object-cover" />
                                <div>
                                    <h2 className="text-xl font-bold mb-2">{testimonial.name}</h2>
                                    <p className="text-gray-600 mb-2">{testimonial.company}</p>
                                </div>
                            </div>
                            <div className="flex mb-4">
                                {[...Array(5)].map((_, i) => (
                                    <svg
                                        key={i}
                                        className={`w-4 h-4 ${i < testimonial.rating ? "text-yellow-300" : "text-gray-200"}`}
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 22 20"
                                    >
                                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                    </svg>
                                ))}
                            </div>
                            <p>{testimonial.review}</p>
                        </div>
                    ))}
                </div>

                {/* Swiper layout for small and medium screens */}
                <div className="block xl:hidden">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        pagination={Pagination}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2, // For md screens, show two slides
                            },
                            1024: {
                                slidesPerView: 3, // For md screens, show two slides
                            },

                        }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className={` md:p-8 p-4 rounded-lg shadow-lg flex flex-col items-center hover:border hover:border-[#EC75E4] hvr-grow ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                    <div className="flex gap-4 place-items-center">
                                        <img src={testimonial.image} alt="Customer Image" className="w-20 h-20 rounded-full mb-4 object-cover" />
                                        <div>
                                            <h2 className="text-xl font-bold mb-2">{testimonial.name}</h2>
                                            <p className="text-gray-600 mb-2">{testimonial.company}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        {[...Array(5)].map((_, i) => (
                                            <svg
                                                key={i}
                                                className={`w-4 h-4 ${i < testimonial.rating ? "text-yellow-300" : "text-gray-200"}`}
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 22 20"
                                            >
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                            </svg>
                                        ))}
                                    </div>
                                    <p>{testimonial.review}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
