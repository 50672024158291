import React, {useContext} from 'react'
import ThemeContext from './ThemeContext';


const Contribute = () => {
    const { isDarkMode } = useContext(ThemeContext); 

    return (
        <>
            <div className={`bg-[#09090B]  flex flex-col items-center justify-center lg:h-[60vh] h-[57vh] p-6 md:p-0 ${isDarkMode ? 'contribute-bgg bg-[#09090B] text-white border-[#ffffff]'  : 'bg-white text-black border-[black] bgggg' }`}>
                <div className='contribute-bggg '>
                <div className="text-center ">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">
                            Contribute to Genius's
                        </h1>
                        <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl font-semibold mb-8">future</h2>
                        <p className="text-lg mb-8">
                            Unleash the genius's AI potential. Use the open AI chat app, Genius, to
                            increase your productivity.
                        </p>
                        <button className="all-btn text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hvr-bounce-in">
                            Try It Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contribute
