import React, { useContext } from 'react'
import ThemeContext from './ThemeContext';
import Navbar from '../Tryitnow/Navbar';
import Footer from './Footer';

const Privacypolicy = () => {
    const { isDarkMode } = useContext(ThemeContext);
    return (
        <>
            <div
                className={` ${isDarkMode
                    ? "bg-[#09090B] text-white border-[#ffffff] policy-bgg"
                    : "bg-white text-black border-[black] policy-bgggg"
                    }`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 policy-bggg">
                    <div className=''>
                        <div className="">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">
                                Privacy Policy
                            </h1>
                        </div>
                        <div className="glass rounded-3xl md:p-10">
                            <h2 className="text-2xl font-bold  mb-4">1. Introduction</h2>
                            <p className=" mb-6">Welcome to Genius Chatbot! We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you interact with our chatbot services on our website.</p>
                            <h2 className="text-2xl font-bold  mb-4">2. Information We Collect</h2>
                            <h3 className="text-xl font-bold  mb-2">Personal Information</h3>
                            <p className=" mb-4">When you use our chatbot, we may collect personal information that you provide voluntarily, such as your name, email address, and any other details you choose to share during the interaction.</p>
                            <h3 className="text-xl font-bold  mb-2">Usage Data</h3>
                            <p className=" mb-4">We may collect information about how you use our chatbot, including your interactions, questions asked, and responses received. This helps us improve our services and user experience.</p>
                            <h3 className="text-xl font-bold  mb-2">Cookies and Tracking Technologies</h3>
                            <p className=" mb-4">We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us recognize you and remember your preferences.</p>
                            <h2 className="text-2xl font-bold  mb-4">3. How We Use Your Information</h2>
                            <p className=" mb-4">We use the information we collect to:</p>
                            <ul className="list-disc  ml-6 mb-4">
                                <li>Provide, operate, and maintain our chatbot services.</li>
                                <li>Improve and personalize your experience with our chatbot.</li>
                                <li>Communicate with you, including responding to your queries and providing support.</li>
                                <li>Analyze usage patterns to enhance the functionality and performance of our services.</li>
                                <li>Comply with legal obligations and enforce our terms and policies.</li>
                            </ul>
                            <h2 className="text-2xl font-bold  mb-4">4. Sharing Your Information</h2>
                            <p className=" mb-4">We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
                            <ul className="list-disc  ml-6 mb-4">
                                <li>Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website and providing our services, provided that these parties agree to keep your information confidential.</li>
                                <li>Legal Requirements: We may disclose your information if required to do so by law or in response to legal requests, such as subpoenas or court orders.</li>
                                <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our business, your information may be transferred as part of the transaction.</li>
                            </ul>
                            <h2 className="text-2xl font-bold  mb-4">5. Data Security</h2>
                            <p className=" mb-4">We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                            <h2 className="text-2xl font-bold  mb-4">6. Your Choices</h2>
                            <p className=" mb-4">You have the right to:</p>
                            <ul className="list-disc  ml-6 mb-4">
                                <li>Access: Request a copy of the personal information we hold about you.</li>
                                <li>Update: Correct or update inaccurate or incomplete information.</li>
                                <li>Delete: Request the deletion of your personal information, subject to certain exceptions.</li>
                            </ul>
                            <p className=" mb-4">To exercise your rights, please contact us using the information provided below.</p>
                            <h2 className="text-2xl font-bold  mb-4">7. Changes to This Privacy Policy</h2>
                            <p className=" mb-4">We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new policy on our website and updating the effective date.</p>
                            <h2 className="text-2xl font-bold  mb-4">8. Contact Us</h2>
                            <p className=" mb-4">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at: [Contact Information]</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Privacypolicy
