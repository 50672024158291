import React, { useContext } from 'react';
import ThemeContext from './ThemeContext';
import Navbar from '../Tryitnow/Navbar';
import Footer from './Footer';
import icon1 from '../assets/Images/genius icon/Icon.png';
import icon2 from '../assets/Images/genius icon/Icon-2.png';
import icon3 from '../assets/Images/genius icon/Icon-3.png';
import icon4 from '../assets/Images/genius icon/Icon-4.png';
import icon5 from '../assets/Images/genius icon/Icon-5.png';
import icon6 from '../assets/Images/genius icon/Icon-1.png';

const featuresData = [
    {
        id: 1,
        title: 'Cutting-Edge Innovation',
        description: 'Experience groundbreaking technological advancements that push the boundaries of what\'s possible, revolutionizing industries and transforming the way we live and work.',
        icon: icon1,
        className: 'border-b md:border-b-0 md:border-e lg:border-e'
    },
    {
        id: 2,
        title: 'Seamless Connectivity',
        description: 'Stay connected anytime, anywhere with our robust and reliable network infrastructure, ensuring uninterrupted communication and effortless access to the digital world.',
        icon: icon6,
        className: 'border-b md:border-b-0 md:border-e lg:border-e'
    },
    {
        id: 3,
        title: 'Intuitive User Interface',
        description: 'Enjoy a seamless and intuitive user experience with our sleek and user-friendly interface, designed to simplify complex tasks and enhance productivity.',
        icon: icon2,
        className: 'border-b md:border-b-0'
    },
    {
        id: 4,
        title: 'Seamless Collaboration Tools',
        description: 'Gain valuable insights and make data-driven decisions with advanced analytics tools.',
        icon: icon3,
        className: 'border-b md:border-b-0 md:border-e lg:border-e'
    },
    {
        id: 5,
        title: 'Scalable Cloud Infrastructure',
        description: 'Streamline communication and foster teamwork with efficient collaboration tools.',
        icon: icon4,
        className: 'border-b md:border-b-0 md:border-e lg:border-e'
    },
    {
        id: 6,
        title: 'Intelligent Data Analytics',
        description: 'Scale effortlessly with reliable and flexible cloud infrastructure solutions.',
        icon: icon5,
        className: 'border-b md:border-b-0'
    }
];

const Features = () => {
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <>
            <div
                className={` ${isDarkMode ? "bg-[#09090B] text-white border-[#ffffff] policy-bgg" : "bg-white text-black border-[black] policy-bgggg"}`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 policy-bggg">
                    <div className="">
                        <div className="">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">
                                Features of the Genius Chatbot
                            </h1>
                        </div>
                        <div className="glass rounded-3xl md:p-10 p-6">
                            <div className="py-16 px-8">
                                <h1 className="text-4xl font-bold text-center mb-8">
                                    Discover the Tools that Drive Success
                                </h1>
                                <p className="text-center mb-12">
                                    Unleash innovation and accelerate growth with our dynamic product.
                                </p>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-8">
                                    {featuresData.map((feature) => (
                                        <div
                                            key={feature.id}
                                            className={` px-0 py-8 lg:px-8 lg:py-8 md:px-4 md:py-4  ${feature.className}`}
                                        >
                                            <div className='hvr-float pricing'>
                                                <div className="flex flex-col place-items-center mb-4">
                                                    <img src={feature.icon} alt={feature.title} className='h-10 w-10 mb-4' />
                                                    <h2 className="ml-4 font-bold">{feature.title}</h2>
                                                </div>
                                                <p className="text-center">
                                                    {feature.description}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Features;
