import React, { useContext } from 'react'
import ThemeContext from './ThemeContext';
import Navbar from '../Tryitnow/Navbar';
import Footer from './Footer';

const Security = () => {
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <>
            <div
                className={` ${isDarkMode
                    ? "bg-[#09090B] text-white border-[#ffffff] security-bgg"
                    : "bg-white text-black border-[black] security-bgggg"
                    }`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 security-bggg">
                    <div className=''>
                        <div className="">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">
                                Security
                            </h1>
                        </div>
                        <div className="glass rounded-3xl md:p-10 p-6">
                            <p className="text-lg mb-6 ">
                                At Genius, we understand that security and privacy are paramount. That’s why we’ve built our chatbot with advanced security features to ensure your data and interactions remain safe and protected at all times. Here’s how Genius keeps your information secure:
                            </p>
                            <div className="">
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">1. End-to-End Encryption</h2>
                                    <ul className="list-disc mb-6">
                                        <li>All conversations between you and the Genius Chatbot are secured with end-to-end encryption.</li>
                                        <li>This ensures that only you and Genius can access the content of your messages, preventing any third-party from intercepting or viewing your data.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">2. Data Privacy</h2>
                                    <ul className="list-disc mb-6">
                                        <li>Genius strictly adheres to privacy regulations like GDPR (General Data Protection Regulation) and other applicable data privacy laws.</li>
                                        <li>Your personal information is never shared, sold, or misused. We only use your data to improve your experience and to help resolve your queries.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">3. Anonymous Conversations</h2>
                                    <ul className="list-disc mb-6">
                                        <li>You can use the Genius Chatbot anonymously without sharing personal details unless required for a specific query.</li>
                                        <li>When you do provide personal information (e.g., for order tracking or account support), it's used solely for that purpose and is not stored without your consent.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">4. Secure Authentication</h2>
                                    <ul className="list-disc mb-6">
                                        <li>For actions that involve sensitive information, such as account management or order tracking, Genius may require secure authentication.</li>
                                        <li>This ensures that only authorized users can access their personal data through the chatbot.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">5. Regular Security Audits</h2>
                                    <ul className="list-disc mb-6">
                                        <li>Our system undergoes regular security audits and updates to ensure the highest standards of protection.</li>
                                        <li>We continuously monitor and upgrade our security protocols to protect against potential vulnerabilities and threats.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">6. Data Encryption at Rest</h2>
                                    <ul className="list-disc mb-6">
                                        <li>All data collected through the Genius Chatbot is encrypted both in transit and at rest.</li>
                                        <li>This means that even if data storage were compromised, the encrypted information would be unreadable to unauthorized users.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">7. Controlled Data Access</h2>
                                    <ul className="list-disc mb-6">
                                        <li>Only authorized personnel within our organization have access to sensitive data, and even then, access is granted on a need-to-know basis.</li>
                                        <li>We employ strict internal policies and protocols to safeguard your information from misuse or unauthorized access.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">8. Session Timeouts</h2>
                                    <ul className="list-disc mb-6">
                                        <li>For added security, Genius implements session timeouts during prolonged inactivity.</li>
                                        <li>This ensures that if you leave your chat session open, it will automatically close after a certain period, reducing the risk of unauthorized access.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">9. Transparent Data Handling</h2>
                                    <ul className="list-disc mb-6">
                                        <li>You have full control over your data. At any time, you can request to view, modify, or delete any personal data you've shared with Genius.</li>
                                        <li>We provide clear options for managing your data through the chatbot interface.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-bold mb-4">10. Compliance with Industry Standards</h2>
                                    <ul className="list-disc mb-6">
                                        <li>Genius is fully compliant with industry-leading security standards, ensuring your information is handled with the utmost care.</li>
                                        <li>We follow best practices for data security, including secure coding, vulnerability testing, and incident response.</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="text-lg mt-8">
                                At Genius, your security is our top priority. You can trust that every interaction with our chatbot is safe, secure, and private. If you have any concerns or questions regarding security, feel free to contact us for more details.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Security
