import React, { useState, useContext } from 'react';
import Navbar from '../Tryitnow/Navbar';
import ThemeContext from './ThemeContext';
import { FaApple } from "react-icons/fa6";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Footer from './Footer';
import google from '../assets/Images/google.png';
import facebook from '../assets/Images/facebook-login.png';
import { Link, useNavigate } from 'react-router-dom';
import { LoginSocialGoogle } from 'reactjs-social-login';

const Signup = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const navigate = useNavigate(); // useNavigate to handle redirection

    // State for form inputs
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform validation or form submission logic here
        if (!fullName || !email || !mobile || !password) {
            setError("Please fill in all fields");
            return;
        }
        // Reset error on successful submission
        setError('');
        // Submit the form (you can add your form logic here)
        console.log("Form submitted:", { fullName, email, mobile, password });
        // Navigate to login after successful sign-up
        navigate('/login');
    };

    return (
        <>
            <div className={`2xl:h-[100vh] ${isDarkMode ? "bg-[#09090B] text-white login-bgg" : "bg-white text-black login-bgggg"}`}>
                <div className="login-bggg">
                    <Navbar />
                    <div className="container mx-auto flex justify-center items-center mt-14 lg:mt-24">
                        <div className="glass rounded-2xl p-4 md:p-8 w-10/12 lg:w-1/2">
                            <h2 className="text-2xl font-bold mb-6">Sign up</h2>
                            {error && <div className="text-red-500 mb-4">{error}</div>}
                            <form onSubmit={handleSubmit}>
                                {/* Full Name Field */}
                                <div className="mb-4">
                                    <label htmlFor="fullName" className="block text-gray-400 text-sm font-bold mb-2">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        id="fullName"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        className="shadow border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline glass"
                                        required
                                        placeholder="Enter Your Name"
                                    />
                                </div>

                                {/* Email Field */}
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-400 text-sm font-bold mb-2">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="shadow border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline glass"
                                        required
                                        placeholder="example@email.com"
                                    />
                                </div>

                                {/* Mobile Number Field */}
                                <div className="mb-4">
                                    <label htmlFor="mobile" className="block text-gray-400 text-sm font-bold mb-2">
                                        Mobile Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="mobile"
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="shadow border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline glass"
                                        required
                                        placeholder="Enter Your Mobile Number"
                                    />
                                </div>

                                {/* Password Field */}
                                <div className="mb-6">
                                    <label htmlFor="password" className="block text-gray-400 text-sm font-bold mb-2">
                                        Password
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="shadow glass border rounded w-full py-2 px-3 focus:outline-none focus:shadow-outline"
                                            required
                                            placeholder="Enter your password"
                                        />
                                        {/* Toggle button */}
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-transparent focus:outline-none"
                                        >
                                            {showPassword ? (
                                                <IoIosEye className="text-xl" />
                                            ) : (
                                                <IoIosEyeOff className="text-xl" />
                                            )}
                                        </button>
                                    </div>
                                </div>

                                {/* Sign Up Button */}
                                <div className="flex items-center justify-center">
                                    <button
                                        type="submit"
                                        className={`all-btn font-bold py-2 px-4 rounded focus:outline-none w-full ${!fullName || !email || !mobile || !password ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={!fullName || !email || !mobile || !password}
                                    >
                                        Sign up
                                    </button>
                                </div>
                                <div className="text-center mt-2">
                                    Already have an account? <Link to="/login" className="border-b">Login</Link>
                                </div>

                                <div className="text-center mt-4 text-gray-400">Or</div>

                                {/* Social Media Buttons */}
                                <div className="flex justify-center mt-4 gap-6">
                                    <LoginSocialGoogle
                                        client_id="905543553860-676ntf0rjj1ufqakoitnjchaj4nh6ugo.apps.googleusercontent.com"
                                        redirect_uri={window.location.origin + "/pricing"}  // Redirect to pricing page
                                        access_type="offline"
                                        onResolve={({ provider, data }) => {
                                            console.log("Login successful with provider:", provider);
                                            console.log("User data:", data);
                                            window.location.href = "/pricing";  // Redirect to the pricing page after login
                                        }}
                                        onReject={error => {
                                            console.error("Login failed:", error);
                                            alert("Google login failed. Please try again.");
                                        }}
                                    >
                                        <button
                                            aria-label="Sign in with Google"
                                            className="bg-white rounded-full py-2 px-2 font-bold mr-2 focus:outline-none focus:shadow-outline hover:scale-105 transition-transform duration-200"
                                        >
                                            <img src={google} alt="Google logo" className="w-5 h-5" />
                                        </button>
                                    </LoginSocialGoogle>

                                    <button aria-label="Sign up with Apple" className="bg-white rounded-full py-2 px-2 font-bold  mr-2 focus:outline-none hover:scale-105 transition-transform duration-200">
                                        <FaApple className="text-black text-2xl" />
                                    </button>
                                    <button aria-label="Sign up with Facebook" className="bg-white rounded-full py-2 px-2 text-white font-bold focus:outline-none hover:scale-105 transition-transform duration-200">
                                        <img src={facebook} alt="Facebook" className="w-5 h-5" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Signup;
