import React, { useContext } from 'react';
import img from '../../assets/Images/avatar.png';
import { MdOutlineFeedback } from "react-icons/md";
import { LuShieldAlert } from "react-icons/lu";
import { FaSun, FaMoon } from "react-icons/fa";
import ThemeContext from '../../Component/ThemeContext';
import icon from '../../assets/Images/icon.png';

const Navbar = ({isOpen,toggleSidebar}) => {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    return (
        <div className="glass7 flex w-full items-center  py-2 flex-row justify-between">
        <div className='relative h-10 w-10'>
        <button onClick={toggleSidebar} className="focus:outline-none font-color z-50 absolute -left-3 top-2 h-fit w-fit">
                        <img src={icon} alt=""  className={` transition-transform ${isOpen ? '' : 'rotate-180'}`} />
            </button>
        </div>
             
            <div className="flex items-center gap-6 px-4"> {/* Set width for the group of elements */}
                {/* Theme Toggle Switch */}
                <div className="flex items-center w-[100px]"> {/* Set width for the toggle */}
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only"
                            checked={isDarkMode}
                            onChange={toggleTheme}
                        />
                        <div
                            className={`w-24 h-10 rounded-full shadow-inner transition-colors duration-300 relative ${
                                isDarkMode ? "bg-[#EFC502]" : "bg-[#2D2837]"
                            }`}
                        >
                            {/* Light Mode Text */}
                            <span
                                className={`absolute left-2 top-[8px] mx-1 text-base font-black transition-colors duration-300 ${
                                    isDarkMode ? "text-[#FEF3BB]" : "hidden"
                                }`}
                            >
                                Light
                            </span>
                            {/* Dark Mode Text */}
                            <span
                                className={`absolute right-3 top-2 text-base font-black transition-colors duration-300 ${
                                    isDarkMode ? "hidden" : "text-[#736D81]"
                                }`}
                            >
                                Dark
                            </span>
                            {/* Sliding Toggle Button */}
                            <div
                                className={`flex justify-center items-center absolute left-[10px] top-[4px] w-8 h-8 bg-white rounded-full shadow-md transition-transform duration-300 transform ${
                                    isDarkMode ? "translate-x-[48px]" : "translate-x-0"
                                }`}
                            >
                                {isDarkMode ? (
                                    <FaSun className="text-[#FDD835] w-6 h-6" />
                                ) : (
                                    <FaMoon className="text-black w-6 h-6" />
                                )}
                            </div>
                        </div>
                    </label>
                </div>

                {/* Feedback Icon */}
                <div className="flex items-center w-[50px]"> {/* Set width for the feedback icon */}
                    <MdOutlineFeedback className="text-4xl text-white" />
                </div>

                {/* Alert Icon */}
                <div className="relative flex items-center w-[50px]"> {/* Set width for the alert icon */}
                    <span className="bg-[#EC75E4] rounded-full w-3 h-3 absolute top-0 right-3" />
                    <LuShieldAlert className="text-4xl text-white" />
                </div>

                {/* Profile Image */}
                <div className="flex items-center w-[60px]"> {/* Set width for the profile image */}
                    <img
                        src={img}
                        alt="Profile"
                        className="w-12 h-12 rounded-2xl object-cover"
                    />
                </div>
            </div>
        </div>
    );
};

export default Navbar;
