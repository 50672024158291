import React, { useState, useContext } from "react";
import { GoGoal } from "react-icons/go";
import { TbBulbFilled } from "react-icons/tb";
import about1 from "../assets/Images/about-robot.jpeg";
import about2 from "../assets/Images/about-1.png";
import about3 from "../assets/Images/about-2.png";
import about4 from "../assets/Images/about-3.png";
import ThemeContext from './ThemeContext';

const Aboutus = () => {
   

    const { isDarkMode } = useContext(ThemeContext);

    const [data] = useState({
        title: "About Us",
        subtitle: "Innovating the Future with AI",
        description: "At Genius, we are passionate about harnessing the power of artificial intelligence to transform the way people create, work, and innovate. Founded on the belief that AI has the potential to revolutionize everyday tasks, we are dedicated to developing cutting-edge AI solutions that empower individuals and businesses alike.",
        mission: {
            title: "Our Mission",
            description: "We aim to make advanced AI technology accessible and user-friendly.",
            icon: <GoGoal className="text-[#FE7689] text-5xl h-8" />
        },
        vision: {
            title: "Our Vision",
            description: "We envision a world where AI seamlessly integrates with human ingenuity.",
            icon: <TbBulbFilled className="text-[#FE7689] text-6xl h-8" />
        },
        buttonText: "View More Details",
        // images: [
        //     { src: about1, alt: "Robot", className: "absolute lg:top-[-50px] lg:right-5  md:top-[-40px] md:right-[13rem] top-[-50px] right-10 lg:h-[230px] lg:w-[230px] h-[173px] w-[173px] rounded-lg object-cover" },
        //     { src: about2, alt: "AI Concept 1", className: "absolute lg:top-16 lg:left-10 md:top-7 md:left-[14rem] top-7 left-14 lg:h-[151px] lg:w-[153px] h-[115px] w-[113px] rounded-lg object-cover" },
        //     { src: about3, alt: "AI Concept 2", className: "absolute lg:bottom-5 lg:left-10 md:bottom-8 md:left-[14rem] bottom-8 left-16 lg:h-[183px] lg:w-[183px] h-[138px] w-[137px] rounded-lg object-cover" },
        //     { src: about4, alt: "AI Concept 3", className: "absolute lg:bottom-12 lg:right-0 md:bottom-10 md:right-[13rem] bottom-12 right-10 lg:h-[200px] lg:w-[200px] h-[150px] w-[150px] rounded-lg object-cover" }
        // ]

        images: [
            {
                src: about1,
                alt: "Robot",
                className: `absolute 
                            robot
                            lg:top-[-50px] lg:right-[1rem] 
                            xl:top-[-40px] xl:right-2 
                            2xl:top-[-40px] 2xl:right-4 
                            md:top-[-40px] md:right-[1rem] 
                            top-[-50px] right-[1rem] 
                            lg:h-[230px] lg:w-[230px] 
                            xl:h-[210px] xl:w-[210px] 
                            2xl:h-[230px] 2xl:w-[230px] 
                            h-[173px] w-[173px] rounded-lg object-cover`
            },
            {
                src: about2,
                alt: "AI Concept 1",
                className: `absolute 
                            ai1
                            lg:top-16 lg:left-[1rem] 
                            xl:top-10 xl:left-12 
                            2xl:top-20 2xl:left-8 
                            md:top-7 md:left-[1rem] 
                            top-7 left-[1rem] 
                            lg:h-[151px] lg:w-[153px] 
                            xl:h-[170px] xl:w-[173px] 
                            2xl:h-[150px] 2xl:w-[153px] 
                            h-[115px] w-[113px] rounded-lg object-cover`
            },
            {
                src: about3,
                alt: "AI Concept 2",
                className: `absolute 
                            ai2
                            lg:bottom-5 lg:left-[1rem] 
                            xl:bottom-16 xl:left-12 
                            2xl:bottom-5 2xl:left-8 
                            md:bottom-8 md:left-[1rem] 
                            bottom-8 left-[1rem] 
                            lg:h-[183px] lg:w-[183px] 
                            xl:h-[170px] xl:w-[170px] 
                            2xl:h-[170px] 2xl:w-[170px] 
                            h-[138px] w-[137px] rounded-lg object-cover`
            },
            {
                src: about4,
                alt: "AI Concept 3",
                className: `absolute 
                            ai3
                            lg:bottom-12 lg:right-[1rem] 
                            xl:bottom-20 xl:right-2 
                            2xl:bottom-10 2xl:right-4 
                            md:bottom-10 md:right-[1rem] 
                            bottom-12 right-[1rem] 
                            lg:h-[200px] lg:w-[200px] 
                            xl:h-[180px] xl:w-[180px] 
                            2xl:h-[200px] 2xl:w-[200px] 
                            h-[150px] w-[150px] rounded-lg object-cover`
            }
        ]

    });

    return (
        <>
            <div className={` py-16 lg:px-16 ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff]' : 'bg-white text-black border-[black]'} bg-shadow `}>
                <div className="container-fluid mx-auto px-4 xl:flex xl:justify-evenly gap-36">
                    <div className="xl:w-3/6 mb-20 lg:mb-0">
                        <h1 className="text-4xl font-bold mb-4 font-color">{data.title}</h1>
                        <h2 className="text-2xl font-bold mb-8">{data.subtitle}</h2>
                        <p className="mb-8">{data.description}</p>
                        <div className="md:flex gap-4 space-y-4 md:space-y-0">
                            <div className={`hvr-float pricing rounded-lg p-3  lg:w-1/3 ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                <div className="flex gap-4 ">
                                    {data.mission.icon}
                                    <div className="items-center mb-4">
                                        <h3 className="text-2xl font-bold">{data.mission.title}</h3>
                                        <p>{data.mission.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={` hvr-float pricing rounded-lg p-3 lg:w-1/3 ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                <div className="flex gap-4">
                                    {data.vision.icon}
                                    <div className="items-center mb-4">
                                        <h3 className="text-2xl font-bold">{data.vision.title}</h3>
                                        <p>{data.vision.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="all-btn text-black font-bold py-2 px-4 rounded-lg mt-8 hvr-bounce-in">
                            {data.buttonText}
                        </button>
                    </div>

                    <div className="relative about-img flex justify-center lg:mt-20">
                        <div className={`lg:h-[450px] lg:w-[450px] h-[338px] w-[337px] rounded-lg flex justify-items-center  ${isDarkMode ? 'glass'  : ' glass1'}`}>
                            {data.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.src}
                                    alt={image.alt}
                                    className={image.className}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    );
};

export default Aboutus;



