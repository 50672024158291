import React, { useContext, useState, useEffect } from "react";
import "../assets/Css/Style.css";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { MdSlowMotionVideo } from "react-icons/md";
import { FaLocationArrow } from "react-icons/fa6";
import ThemeContext from "./ThemeContext";
import Navbar from "./Navbar";
import logo from "../assets/Images/chat-bot.png";
import { TbNavigationFilled } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import userlogo from '../assets/Images/Whychoose3.png'
const Home = () => {
  const { isDarkMode } = useContext(ThemeContext);

  const [isOpen, setIsOpen] = useState(true); // Open chat by default
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([{ text: "Hey there! Welcome to Genius.", sender: 'system' }]);
  const [isFirstOpen, setIsFirstOpen] = useState(true); // Track if chat was opened for the first time
  const [defaultMessageShown, setDefaultMessageShown] = useState(false); // New state for tracking
  const [isChatOpen, setIsChatOpen] = useState(false); // Controls chat widget visibility
  const [showWelcomeBox, setShowWelcomeBox] = useState(true); // Show welcome box initially

  // Automatically open chat on first load and play sound
  useEffect(() => {
    if (isFirstOpen) {
      setIsOpen(true);
      setIsFirstOpen(false); // Mark it as opened
    }
  }, [isFirstOpen]);



  // Toggle chat widget manually
  const toggleChatWidget = () => {
    setIsOpen(!isOpen);
    if (showWelcomeBox) {
      setShowWelcomeBox(false); // Hide the welcome box when the chat widget is opened for the first time
    }
    setIsChatOpen(!isChatOpen);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle sending a message
  const handleSendMessage = () => {
    if (inputValue.trim()) {
      setMessages([...messages, { text: inputValue, sender: 'user' }]);
      setInputValue('');
    }
    if (inputValue.trim() === "") return;

    setMessages([...messages, { sender: "user", text: inputValue }]);
    setInputValue("");

    // Add default response if not already shown
    if (!defaultMessageShown) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          text: "Hello There! You have any questions? We're here to help!",
        },
      ]);
      setDefaultMessageShown(true); // Mark default message as shown
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (e.g., newline in text area)
      handleSendMessage();
    }
  };

  return (
    <>
      <div
        className={` ${isDarkMode
          ? "bg-[#09090B] text-white bgg"
          : "bg-white text-black bgggg"
          } `}
      >
        <Navbar />

        <div className=" container mx-auto flex justify-center place-items-center pt-36 md:pt-0 h-auto home bggg">
          <div className="2xl:h-[80%] 2xl:w-2/5 xl:w-3/6 xl:h-[90%] lg:w-4/6 lg:h-[80%] w-11/12 rounded-full absolute 2xl:px-20 opacity-10">
            {/* <div className="h-full w-full rounded-full p-20 opacity-40">
                            <div className="h-full w-full rounded-full p-20 opacity-40">
                                <div className="h-full w-full rounded-full  opacity-40"></div>
                            </div>
                        </div> */}
          </div>

          <div className="lg:py-16 2xl:pt-24 md:pt-20  px-4 sm:px-6 w-full lg:px-8 z-10">
            <div className="lg:w-9/12 2xl:w-6/12 xl:w-[60%] md:w-1/2  w-full mx-auto text-center">
              <p className="font-extrabold">Transform Ideas Into Reality</p>
              <h2 className="lg:text-7xl text-4xl font-bold lg:leading-[5.5rem]">
                Examine the <br /> Potential of Genius's
              </h2>
              <h2 className="lg:text-7xl text-4xl font-bold lg:leading-[5.5rem]">
                <span className=" font-color  typing-effect">
                  {" "}
                  AI Chatting
                </span>
              </h2>
              <p className="mt-4 text-sm lg:w-4/5 mx-auto leading-[1.5rem]">
                At Genius, we believe in the power of artificial intelligence
                to transform the way you work and create. Our platform offers
                a suite of advanced AI tools designed to revolutionize your
                writing, coding, and media creation processes.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <Link to="/tryitnow">
                  <button className="inline-flex items-center px-4 py-2 rounded-md all-btn text-black font-bold gap-2  hvr-bounce-in ">
                    Get Started <BsArrowUpRightCircleFill />
                  </button>
                </Link>
                <button className="inline-flex items-center px-4   font-medium rounded-md border border-[#f976a5] gap-2  hvr-bounce-in">
                  <MdSlowMotionVideo />
                  Watch Video
                </button>
              </div>
            </div>
            <div className="social 2xl:top-[-450px] 2xl:left-[350px] xl:top-[-450px] xl:left-[220px] lg:top-[-470px] lg:left-[100px] md:top-[-400px] md:left-[80px] sm:top-[-200px] sm:left-[60px] top-[-370px] left-[40px] relative w-5  md:w-52 flex overflow-hidden h-16">
              <p className="bg-[#FE7689] md:w-32 py-1 text-center text-black font-bold rounded-3xl md:block hidden h-fit">
                Social Media
              </p>
              <FaLocationArrow className="text-[#FE7689] rotate-90 top-[10px] sm:left-[50px] md:top-[30px] md:left-[125px] absolute" />
            </div>

            <div className="social 2xl:top-[-461px] 2xl:left-[1018px] xl:top-[-460px] xl:left-[900px] lg:top-[-520px] lg:left-[750px] md:top-[-430px] md:left-[500px] sm:top-[-280px] sm:left-[400px] top-[-390px] left-[330px] relative w-5 md:w-28 lg:w-52 flex  h-16">
              <div className="sm:top-[15px] lg:left-[-10px] md:top-[30px] md:left-[0px] absolute">
                <FaLocationArrow className="text-[#E976F3] rotate-180" />
              </div>
              <p className="bg-[#E976F3] sm:w-16 md:w-20 py-1 text-center text-black font-bold rounded-3xl md:block hidden h-fit ">
                Chat
              </p>
            </div>

            <div className="social 2xl:top-[-250px] 2xl:left-[320px] xl:top-[-230px] xl:left-[200px] lg:top-[-250px] lg:left-[80px] md:top-[-300px] md:left-[80px] sm:top-[-300px] sm:left-[50px] top-[-210px] left-[70px] relative w-5  md:w-52 flex overflow-hidden h-16">
              <p className="bg-[#FDCD0E] sm:w-16 md:w-20 py-1 text-center text-black font-bold rounded-3xl md:block hidden h-fit">
                Blog
              </p>
              <div className="sm:top-[-5px] sm:left-[0px] md:top-[-15px] md:left-[5px]">
                <FaLocationArrow className="text-[#FDCD0E] " />
              </div>
            </div>

            <div className="social 2xl:top-[-380px] 2xl:left-[1060px] xl:top-[-400px] xl:left-[950px] lg:top-[-399px] lg:left-[800px] md:top-[-350px] md:left-[535px] sm:top-[-100px] sm:left-[450px] top-[-280px] left-[335px] relative w-5 md:w-52  lg:w-44 flex overflow-hidden h-16">
              <div className="top-[-15px] sm:left-[-5px] md:top-[-10px] md:left-[-10px] xl:left-[10px]">
                <FaLocationArrow className="text-[#37C0F8] -rotate-90  " />
              </div>
              <p className="bg-[#37C0F8] sm:w-20 md:w-28 py-1 text-center text-black font-bold rounded-3xl md:block hidden h-fit">
                Coding
              </p>
            </div>
          </div>
        </div>


        <div>
          {/* Chat toggle button */}
          <div className={`fixed  w-16 h-16  top-[90%] right-5 flex justify-center items-center z-20 hvr-grow ${isDarkMode
            ? "glass rounded-full"
            : "glass1 rounded-full"
            }`}>
            <button onClick={toggleChatWidget}>
              {isChatOpen ? (
                <RxCross2 className="h-12 w-12 text-[#ec75e4]" />
              ) : (
                <img src={logo} alt="Genius Logo" className="h-12 w-12" />
              )}
            </button>
          </div>

          {/* Welcome box (shown on first load only) */}
          {showWelcomeBox && (
            <div className={`fixed bottom-[1rem] lg:bottom-[1.5rem] right-[5rem]  z-20  p-4 rounded-lg w-[280px] md:w-[350px]  ${isDarkMode ? "tooltip-bg text-white":"tooltip-bg1 text-black"}`}>
              <div className="ps-4 md:ps-6">
                <p className="font-bold">Welcome back!</p>
                <p>How May I Help You Today?</p>
              </div>
            </div>
          )}

          {/* Chat widget */}
          {isChatOpen && !showWelcomeBox && (
            <div
              className={` border-all chat-widget rounded-lg xl:w-[400px] 2xl:w-[500px] w-[400px] md:w-[465px] fixed bottom-[7rem] right-[2rem] shadow-lg z-20 ${isDarkMode
                ? "bg-[#09090B] opacity-100 text-white"
                : "bg-white text-black"
                }`}
            >
              {/* Chat header */}
              <div className="chat-header flex items-center p-4 rounded-t-lg border-b">
                <img
                  src={logo}
                  alt="Genius Logo"
                  className="w-8 h-8 mr-2 zoom-animation"
                />
                <div>
                  <h2 className="font-bold">Genius</h2>
                  <p className="text-sm text-green-400">Online</p>
                </div>
              </div>

              {/* Chat body */}
              <div className="chat-body flex-1 p-4 overflow-y-scroll h-[50vh]">
                <div className="space-y-4">
                  {/* Display messages */}
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={`flex items-center gap-4 ${message.sender === "user" ? "justify-end" : "justify-start"}`}
                    >
                      {/* If it's a user message, display the message bubble first, then the logo */}
                      {message.sender === "user" ? (
                        <>
                          <div
                            className={`message p-2 rounded-md w-fit border ${isDarkMode ? "glass" : "glass5"} text-right bg-blue-100`}
                          >
                            <p>{message.text}</p>
                          </div>
                          <img
                            src={userlogo}
                            alt="User Logo"
                            className="w-6 h-6 object-cover rounded-full"
                          />
                        </>
                      ) : (
                        <>
                          {/* Non-user messages: logo first, then the message */}
                          <img
                            src={logo}
                            alt="Genius Logo"
                            className="w-6 h-6"
                          />
                          <div
                            className={`message p-2 rounded-md w-fit border ${isDarkMode ? "glass" : "glass5"} text-left bg-gray-100`}
                          >
                            <p>{message.text}</p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>



              {/* Chat input */}
              <div className="chat-input p-2 flex items-center">
                <input
                  type="text"
                  placeholder="Type Your Message here..."
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className={`bg-transparent px-4 py-2 w-full rounded-lg outline-none ${isDarkMode ? "glass" : "glass1"
                    }`}
                />
                <button
                  onClick={handleSendMessage}
                  className="ml-2 p-2 rounded-full all-btn"
                >
                  <TbNavigationFilled className="w-6 h-6 rotate-90 text-black" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
