import React, { useContext } from 'react'
import ThemeContext from './ThemeContext';
import Navbar from '../Tryitnow/Navbar';
import Footer from './Footer';

const Howtouse = () => {
    const { isDarkMode } = useContext(ThemeContext);
    return (
        <>
            <div
                className={` ${isDarkMode
                    ? "bg-[#09090B]  border-[#ffffff] text-white policy-bgg"
                    : "bg-white text-black border-[black]  policy-bgggg"
                    }`}
            >
                <Navbar />
                <div className="container mx-auto mb-5 p-5 policy-bggg">
                    <div className=''>
                        <div className="">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">
                                How to Use the Genius Chatbot
                            </h1>
                        </div>
                        <div className="glass rounded-3xl md:p-10">
                            <p className="text-lg mb-8">
                            Welcome to Genius Chatbot, your intelligent assistant designed to provide fast and accurate answers to all your queries. Follow the simple steps below to get started:
                            </p>
                            <div className="gap-8">
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">1. Opening the Chatbot</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>Look for the Genius Chatbot icon at the bottom right corner of any page on our website.</li>
                                        <li>Click on the icon, and the chatbot window will pop up, ready to assist you.</li>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">2. Starting a Conversation</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>Once the chatbot window opens, you'll be greeted with a welcome message.</li>
                                        <li>Type in your question or choose from the available options (like Support, Account Help, Order Status, etc.).</li>
                                        <li>The chatbot is designed to respond instantly and help guide you through solutions.</li>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">3. Ask Anything</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>You can ask Genius about a wide range of topics:</li>
                                        <ul className="list-disc list-inside ml-4">
                                            <li>Product information</li>
                                            <li>Account setup and troubleshooting</li>
                                            <li>Billing and payment inquiries</li>
                                            <li>Technical support</li>
                                            <li>Tracking orders</li>
                                            <li>Frequently asked questions</li>
                                        </ul>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">4. Live Support</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>If the chatbot cannot resolve your issue, it will seamlessly connect you with a live support agent (during business hours).</li>
                                        <li>To request a human agent type "Speak to an agent" or "Live support" at any time.</li>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">5. Tracking Your Orders</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>Want to check your order status? Simply type your order number into the chatbot, and it will provide real-time tracking updates.</li>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">6. Knowledge Base Access</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>The Genius Chatbot is connected to our extensive Knowledge Base.</li>
                                        <li>You can browse articles, tutorials, and guides directly through the chat interface to find step-by-step solutions.</li>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">7. Multilingual Support</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>Our chatbot is equipped to respond in multiple languages. You can start a conversation in your preferred language, and it will reply accordingly.</li>
                                    </ul>
                                </div>
                                <div className=" rounded-lg p-3">
                                    <h2 className="text-xl font-bold  mb-4">8. Security and Privacy</h2>
                                    <ul className="list-disc list-inside ">
                                        <li>Your privacy is important to us. All conversations with the Genius Chatbot are secure, and your personal data is never shared without your consent.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Howtouse
