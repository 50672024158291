// import logo from './logo.svg';
import React from "react";
import "./App.css";
import Landingpage from "./Component/Landingpage";
import { ThemeProvider } from "./Component/ThemeContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Component/Navbar";
import Getstart from "./Tryitnow/Getstart";
import Login from "./Component/Login";
import Pricing from "./Component/Pricing";
import Privacypolicy from "./Component/Privacypolicy";
import Helpcenter from "./Component/Helpcenter";
import Howtouse from "./Component/Howtouse";
import Signup from "./Component/Signup";
import Security from "./Component/Security";
import Features from "./Component/Features";
import Aboutusnav from "./Component/Aboutusnav";
import Dahboard from "./Dashboard/Dashboard";


function App() {
  return (
    <>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/navbar" element={<Navbar />} />
            <Route path="/" element={<Landingpage />} />
            <Route path="/tryitnow" element={<Getstart />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/helpcenter" element={<Helpcenter />} />
            <Route path="/howtouse" element={<Howtouse />} />
            <Route path="/security" element={<Security />} />
            <Route path="/features" element={<Features />} />
            <Route path="/aboutus" element={<Aboutusnav />} />
            <Route path="/dashboard/*" element={<Adminroutes />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

const Adminroutes = () => {
  return (
    <>
      <Routes>
        <Route path="/*" Component={Dahboard} />
      </Routes>
    </>
  )
}

export default App;

