import React, { useContext, useState, useEffect } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { RiImageAddLine } from "react-icons/ri";
import { BsMic } from 'react-icons/bs';
import { BiCopy } from 'react-icons/bi';
import { AiOutlineLike, AiOutlineDislike, AiOutlineReload } from 'react-icons/ai';
import logo from '../../assets/Images/chat-bot.png';
import icon1 from '../../assets/Images/vector.png';
import icon2 from '../../assets/Images/Vector (1).png';
import icon3 from '../../assets/Images/Vector (2).png';
import icon4 from '../../assets/Images/vector (3).png';
import userImage from '../../assets/Images/Ellipse 130 (1).png'; // Add your user image
import serverimg from '../../assets/Images/chat-bot.png'; // Add your server image
import ThemeContext from '../../Component/ThemeContext';

const Home = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [searchText, setSearchText] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [showChatBody, setShowChatBody] = useState(false);
    const [messages, setMessages] = useState([]); // Store chat messages (text + images)
    const [lastInputTime, setLastInputTime] = useState(null); // Store last input time

    // Initialize SpeechRecognition
    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recog = new SpeechRecognition();
            recog.continuous = false;
            recog.interimResults = false;
            setRecognition(recog);
        } else {
            console.error('Speech Recognition not supported');
        }
    }, []);

    // Simulate server response
    const mockServerResponse = () => {
        const dateTime = new Date().toLocaleString();
        return {
            type: 'server',
            content: 'Designing for accessibility in user interfaces (UI) is about ensuring that all users, including those with disabilities, can interact with your product effectively.',
            dateTime,
            serverimg,
            actions: {
                reload: false,
                copy: false,
                like: false,
                dislike: false
            }
        };
    };

    // Handle file upload
    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const dateTime = new Date().toLocaleString();
                setMessages(prevMessages => [...prevMessages, { type: 'user', content: e.target.result, format: 'image', dateTime, userImage }]);
                setLastInputTime(dateTime);
                setShowChatBody(true);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle speech input
    const handleSpeechInput = () => {
        if (!recognition) return;

        recognition.onstart = () => {
            setIsListening(true);
        };

        recognition.onend = () => {
            setIsListening(false);
            if (searchText.trim()) {
                handleSend(); // Automatically send the message after speech input
            }
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setSearchText(transcript); // Set the searchText to the spoken words
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            setIsListening(false);
        };

        recognition.start();
    };

    // Handle message send (User Message)
    const handleSend = () => {
        if (searchText.trim()) {
            const dateTime = new Date().toLocaleString();
            setMessages(prevMessages => [...prevMessages, { type: 'user', content: searchText, format: 'text', dateTime, userImage }]);
            setLastInputTime(dateTime);
            setShowChatBody(true);
            setSearchText('');

            // Simulate a server response after a delay
            setTimeout(() => {
                const serverMessage = mockServerResponse();
                setMessages(prevMessages => [...prevMessages, serverMessage]);
            }, 1000);
        }
    };

    // Handle actions (reload, copy, like, dislike) for server messages
    const handleAction = (action, index) => {
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            if (action === 'reload') {
                const newMessage = mockServerResponse(); // Get new message from mock server
                updatedMessages[index] = { ...updatedMessages[index], ...newMessage }; // Update the message
            } else if (action === 'copy') {
                navigator.clipboard.writeText(updatedMessages[index].content);
            } else if (action === 'like') {
                updatedMessages[index].actions.like = !updatedMessages[index].actions.like;
                if (updatedMessages[index].actions.like) {
                    updatedMessages[index].actions.dislike = false; // Ensure dislike is toggled off if like is on
                }
            } else if (action === 'dislike') {
                updatedMessages[index].actions.dislike = !updatedMessages[index].actions.dislike;
                if (updatedMessages[index].actions.dislike) {
                    updatedMessages[index].actions.like = false; // Ensure like is toggled off if dislike is on
                }
            }
            return updatedMessages;
        });
    };


    // Handle 'Enter' key to send message
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        }
    };

    return (
        <div className={`h-[94vh] ${isDarkMode ? "bg-[#09090B] text-white dashboard-bgg" : "bg-white text-black dashboard-bgggg"} flex flex-col`}>
            <div className="dashboard-bggg mb-5 mt-5 flex-grow overflow-y-scroll">
                {!showChatBody && (
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={logo} alt="" className='w-14 zoom-animation' />
                        <div className="text-2xl font-bold mb-4 font-color">Your Daily AI Assistant</div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="bg-[#282A2FDB] text-white rounded-md p-4 flex flex-col h-52 place-content-evenly">
                                <img src={icon1} alt="" className='w-8' />
                                <div className="mt-2 text-sm">Create a crossword puzzle for me</div>
                            </div>
                            <div className="bg-[#282A2FDB] text-white rounded-md p-4 flex flex-col h-52 place-content-evenly">
                                <img src={icon2} alt="" className='w-8' />
                                <div className="mt-2 text-sm">Plan a budget for my vacation</div>
                            </div>
                            <div className="bg-[#282A2FDB] text-white rounded-md p-4 flex flex-col h-52 place-content-evenly">
                                <img src={icon3} alt="" className='w-8' />
                                <div className="mt-2 text-sm">Ethical implications of AI</div>
                            </div>
                            <div className="bg-[#282A2FDB] text-white rounded-md p-4 flex flex-col h-52 place-content-evenly">
                                <img src={icon4} alt="" className='w-8' />
                                <div className="mt-2 text-sm">Calendar for the whole month</div>
                            </div>
                        </div>
                    </div>
                )}
                {showChatBody && (  
                    <div className="flex flex-col items-center justify-end h-full mx-auto w-7/12">
                        <div className="p-4 rounded-lg w-11/12 space-y-4 h-[60vh]">
                            <p className='text-center'>Today</p>
                            {messages.map((message, index) => (
                                <div key={index} className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}>
                                    {message.type === 'user' ? (
                                        <div className="">
                                            <div className='flex justify-center place-items-center mb-2 gap-4'>
                                                <span className="text-xs text-gray-500 ml-2">{message.dateTime}</span>
                                                <img src={message.userImage} alt="User" className="w-8 h-8 rounded-full mr-2" />
                                            </div>
                                            {message.format === 'image' ? (
                                                <img src={message.content} alt="User uploaded" className="max-w-xs rounded-md" />
                                            ) : (
                                                <p className="glass p-2 rounded-md">{message.content}</p>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-start">
                                            <div className='flex gap-4 justify-center place-items-center mb-2'>
                                                <div className='flex gap-2 justify-center place-items-center'>
                                                    <img src={message.serverimg} alt="User" className="w-8 h-8 rounded-full mr-2 zoom-animation" />
                                                    <span className="text-lg font-bold font-color">Genius</span>
                                                </div>
                                                <span className="text-xs text-gray-500">{message.dateTime}</span>
                                            </div>
                                            <div className='glass flex flex-col items-start p-8 rounded-lg'>
                                                {message.format === 'image' ? (
                                                    <img src={message.content} alt="Server response" className="max-w-xs rounded-md" />
                                                ) : (
                                                    <p className="p-2 rounded-md">{message.content}</p>
                                                )}
                                                <div className="flex space-x-2 mt-2">
                                                    <AiOutlineReload
                                                        className="cursor-pointer"
                                                        onClick={() => handleAction('reload', index)}
                                                    />
                                                    <BiCopy
                                                        className="cursor-pointer"
                                                        onClick={() => handleAction('copy', index)}
                                                    />
                                                    <AiOutlineLike
                                                        className={`cursor-pointer ${message.actions?.like ? 'text-green-500' : ''}`}
                                                        onClick={() => handleAction('like', index)}
                                                    />
                                                    <AiOutlineDislike
                                                        className={`cursor-pointer ${message.actions?.dislike ? 'text-red-500' : ''}`}
                                                        onClick={() => handleAction('dislike', index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-4 mt-5'>
                                                <button className='btn-allside px-4 py-2 rounded-lg'>
                                                    <span className=" text-color">Can our perception of reality be trusted?</span>
                                                </button>
                                                <button className='btn-allside px-4 py-2 rounded-lg'>
                                                    <span className=" text-color">How do different philosophers define the self?</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="flex items-center space-x-2 w-7/12 p-4 mx-auto rounded-lg">
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter a prompt here"
                    className="flex-grow p-2 rounded-lg bg-[#3B3D40] text-white"
                />
                <button
                    onClick={handleSpeechInput}
                    className={`all-btn rounded-lg p-2 ${isListening ? 'text-green-500' : 'text-white'}`}
                    disabled={isListening}
                >
                    <BsMic className='text-2xl' />
                </button>
                <label className="cursor-pointer p-2 all-btn rounded-lg">
                    <RiImageAddLine className="text-2xl text-white" />
                    <input
                        type="file"
                        onChange={handleUpload}
                        className="hidden"
                    />
                </label>
                <button onClick={handleSend} className="flex items-center all-btn rounded-lg p-2">
                    <AiOutlineSend className='text-2xl text-white' />
                </button>
            </div>
        </div>
    );
};

export default Home;
