import React, { useState, useContext } from 'react';
import { HiMiniHandThumbUp } from "react-icons/hi2";
import icon from '../assets/Images/fluent_prompt-black-32-filled.png';
import choose1 from '../assets/Images/Whychoose1.png';
import choose2 from '../assets/Images/Whychoose2.png';
import choose3 from '../assets/Images/Whychoose3.png';
import choose4 from '../assets/Images/Whychoose4.png';
import ThemeContext from './ThemeContext';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Flag from 'react-world-flags';


const Whychoose = () => {

    // SwiperCore.use([Navigation, Pagination, Autoplay]);

    const countries = [
        { name: 'Germany', code: 'DE' },
        { name: 'Canada', code: 'CA' },
        { name: 'India', code: 'IN' },
        { name: 'Australia', code: 'AU' },
        { name: 'France', code: 'FR' },
        { name: 'Japan', code: 'JP' },
        { name: 'Brazil', code: 'BR' },
        { name: 'Mexico', code: 'MX' },
        { name: 'Italy', code: 'IT' },
        { name: 'China', code: 'CN' },
    ];
    
    const countries1 = [
        { name: 'South Korea', code: 'KR' },
        { name: 'Argentina', code: 'AR' },
        { name: 'Spain', code: 'ES' },
        { name: 'Netherlands', code: 'NL' },
        { name: 'Sweden', code: 'SE' },
        { name: 'Norway', code: 'NO' },
        { name: 'Turkey', code: 'TR' },
        { name: 'Saudi Arabia', code: 'SA' },
        { name: 'Singapore', code: 'SG' },
        { name: 'New Zealand', code: 'NZ' },
        { name: 'Chile', code: 'CL' },
    ];
    

    const { isDarkMode } = useContext(ThemeContext);

    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");

    const handleCategoryChange = (e) => setCategory(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);

    const data = {
        title: "Why Choose Genius?",
        subtitle: "Discover the Benefits of Genius",
        description: "This concise version effectively communicates the main advantages of using Genius, making it easy for potential users to quickly grasp why they should choose your platform.",
        formTitle: "Write Category",
        formDescriptionTitle: "Describe What You Want",
        buttonText: "Generate Now",
        userInterfaceTitle: "User-Friendly Interface",
        userInterfaceDescription: "Genius is designed with simplicity in mind, ensuring that users of all skill levels can navigate and utilize our AI tools effortlessly.",
        multilingualTitle: "Multilingual",
        multilingualDescription: "Proficient in comprehending and producing content across multiple languages, enabling effective communication and engagement across diverse audiences.",
        efficiency: "99%",
        rating: "4.9",
        usersRated: [
            { src: choose1, alt: "user1" },
            { src: choose2, alt: "user2" },
            { src: choose3, alt: "user3" },
            { src: choose4, alt: "user4" },
        ],
        ratingDescription: "Rated by over 100,000 users worldwide."
    };

    return (
        <div className={`flex items-center justify-center xl:px-16 px-4  ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff]' : 'bg-white text-black border-[black] '}`}>
            <div className="container-fluid mx-auto">
                <div className="rounded-lg ">
                    <div className="md:p-6 Why">
                        <div className='xl::flex justify-between place-items-center mb-5'>
                            <div className='xl:w-6/12'>
                                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-4 font-color">{data.title}</h2>
                                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4">{data.subtitle}</h1>
                            </div>
                            <p className=" mb-6">{data.description}</p>
                        </div>

                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 text-white">
                            <div className={`choose p-6 rounded-lg  ${isDarkMode ? 'bg-[#18181B] text-white  ' : 'bg-[#7C7C7C0D] text-black '}`}>
                            <div className={`choose md:p-6 p-2 rounded-lg  ${isDarkMode ? 'bg-[#212124] text-white ' : 'bg-[#EAE9E9] text-black  '}`}>
                                    <h3 className="text-xl font-bold mb-4">{data.formTitle}</h3>
                                    <input
                                        type="text"
                                        value={category}
                                        onChange={handleCategoryChange}
                                        placeholder="Type Here"
                                        className={`w-full px-3 py-2 rounded-lg focus:outline-none bg-transparent ${isDarkMode ? 'glass ' : 'glass1'}`}
                                    />
                                    <h3 className="text-xl font-bold mt-4 mb-4">{data.formDescriptionTitle}</h3>
                                    <textarea
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        placeholder="Type Here"
                                        className={`w-full px-3 py-2 rounded-lg  focus:outline-none  resize-none bg-transparent ${isDarkMode ? 'glass ' : 'glass1'}`}
                                        rows="4"
                                    ></textarea>
                                    <button className={`all-btn  font-bold py-2 px-4 rounded-lg mt-4 w-full flex justify-center gap-4 ${isDarkMode ? ' text-black' : ' text-white '}`}>
                                        {data.buttonText} <img src={icon} alt="" className='w-5 h-5' />
                                    </button>
                                    <h3 className="text-xl font-bold mt-6 mb-4">{data.userInterfaceTitle}</h3>
                                    <p className="text-gray-400">{data.userInterfaceDescription}</p>
                                </div>
                            </div>

                            <div>
                                <div className={` md:p-6 p-2 rounded-lg ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff] glass' : 'bg-[#EAE9E9] text-black border-[black] '}`}>
                                    <div className="country-swiper-container pb-8 ">
                                        <Swiper
                                            spaceBetween={10}
                                            slidesPerView={2}
                                            loop={true}
                                            className="mySwiper"
                                            breakpoints={{
                                                425: {
                                                    slidesPerView: 2,
                                                },
                                                640: {
                                                    slidesPerView: 3,
                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                },
                                                1024: {
                                                    slidesPerView: 4,
                                                },


                                            }}
                                        >
                                            {countries.map((country, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className={`country-slide flex  gap-4 items-center  rounded-3xl justify-center p-2 ${isDarkMode ? 'bg-[#212124] text-white ' : 'bg-[#D9D9D9] text-black  '}`}>
                                                        {/* Using the Flag component to render the flag */}
                                                        <Flag code={country.code} alt={`${country.name} Flag`} className="w-8 h-8" />
                                                        <p className="text-lg font-semibold">{country.name}</p>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                    <div className="country-swiper-container pb-8">
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={2}
                                            loop={true}
                                            autoplay={true}
                                            breakpoints={{
                                                425: {
                                                    slidesPerView: 2,
                                                },
                                                640: {
                                                    slidesPerView: 3,
                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                },
                                                1024: {
                                                    slidesPerView: 4,
                                                },


                                            }}

                                        >
                                            {countries1.map((country, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className={`country-slide flex  gap-4 items-center  rounded-3xl justify-center p-2 ${isDarkMode ? 'bg-[#212124] text-white ' : 'bg-[#D9D9D9] text-black  '}`}>
                                                        {/* Using the Flag component to render the flag */}
                                                        <Flag code={country.code} alt={`${country.name} Flag`} className="w-8 h-8" />
                                                        <p className="text-lg font-semibold">{country.name}</p>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>

                                    <h3 className="text-xl font-bold mb-4">{data.multilingualTitle}</h3>
                                    <p className="text-gray-400 mb-4">{data.multilingualDescription}</p>
                                </div>
                                <div className="flex mt-6 gap-4 rating">
                                    <div className={`users  p-2 md:p-4 rounded-lg w-1/2 text-center flex flex-col place-items-center ${isDarkMode ? 'bg-[#18181B] text-white border-[#ffffff] ' : 'bg-[#EAE9E9] text-black border-[black] '}`}>
                                        <HiMiniHandThumbUp className='text-5xl text-[#EBA352]' />
                                        <span className="ml-2 text-xl font-bold py-3">{data.efficiency}</span>
                                        <p className="font-bold mt-2">Efficient</p>
                                    </div>
                                    <div className={`  users  p-2 md:p-4 rounded-lg w-1/2 ${isDarkMode ? 'bg-[#18181B] text-white border-[#ffffff] ' : 'bg-[#EAE9E9] text-black border-[black] '}`}>
                                        <span className="text-4xl font-bold">{data.rating}</span>
                                        <div className="flex items-center ml-2 py-5">
                                            {data.usersRated.map((user, index) => (
                                                <img
                                                    key={index}
                                                    src={user.src}
                                                    alt={user.alt}
                                                    className="rounded-full w-8 h-8 object-cover"
                                                />
                                            ))}
                                        </div>
                                        <p className="font-bold mt-2">{data.ratingDescription}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Whychoose;
