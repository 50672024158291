import React, { useRef, useState , useContext } from 'react';
import img from '../assets/Images/howitwork.png'
import icon from '../assets/Images/fluent_prompt-32-filled.png'
import { FaArrowRightLong } from "react-icons/fa6";
import ThemeContext from './ThemeContext';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const Howitwork = () => {

    const { isDarkMode } = useContext(ThemeContext); 

    const sections = [
        {
            title: "How IT Work",
            subtitle: "Connect with AI Chat bot",
            description: "This concise version provides a quick overview of the process, making it easy for users to understand how to get started with Genius's AI tools.",
            list: [
                "Quick Registration",
                "Versatile Tools",
                "Simple Inputs",
                "Instant Outputs"
            ],
            buttonText: "Try It Now"
        }
    ];

    const cards = [
        {
            number: "01",
            title: "Sign Up",
            content: "Creating an account is quick and easy. Simply sign up with your email address and gain instant access to all our AI-powered tools."
        },
        {
            number: "02",
            title: "Choose Your Feature",
            content: "Select the AI tool that fits your needs. Whether you want to rewrite sentences, generate code, create custom templates, produce videos, or design."
        },
        {
            number: "03",
            title: "Input Your Data",
            content: "Provide the necessary input for the tool you've chosen. This could be text, code snippets, video footage, or image requirements."
        },
        {
            number: "04",
            title: "Get Results",
            content: "Watch as our advanced AI technology processes your input and delivers high-quality results in seconds."
        }
    ];
    return (
        <>
            <div className={`py-16 px-7 xl:px-16 bg-[#09090B] h-fit  ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff]'  : 'bg-white text-black border-[black] '} ` }>
                <div className="container-fluid mx-auto">
                    <div className="flex flex-col lg:flex-row  justify-between gap-20 h-max">

                        <div className={`lg:w-1/2 w-full flex md:justify-center`}>
                            <img
                                src={img}
                                alt="A boy riding a bike"
                                className={`riding rounded-xl lg:h-[516px] lg:w-[516px] md:h-fit md:w[60%] h-[331px] w-[90%]  ${isDarkMode ? 'ridingshadow'  : ' ridingshadow1'}`}
                            />
                        </div>

                        <div className="lg:w-1/2">
                            {sections.map((section, index) => (
                                <div key={index}>
                                    <h1 className='text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl font-color  font-bold mb-6'>{section.title}</h1>
                                    <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl  font-bold mb-4">{section.subtitle}</h2>
                                    <p className="text-lg mb-6">{section.description}</p>
                                    <ul className="list-disc ml-6">
                                        {section.list.map((item, idx) => (
                                            <li key={idx} className="mb-2">{item}</li>
                                        ))}
                                    </ul>
                                    <button className="all-btn text-black font-bold py-2 px-4 rounded mt-4 flex gap-4 place-items-center hvr-bounce-in">
                                        {section.buttonText}<FaArrowRightLong />

                                    </button>
                                    <div className='relative top-[5rem] lg:top-[-1rem] lg:left-[-29rem] lg:w-9/12 xl:top-[4rem] xl:left-[-36rem] 2xl:top-[5rem] 2xl:left-[-45rem] 2xl:w-[48%] xl:w-7/12 w-6/12 h-10 bg-[#09090B] rounded-lg hidden lg:flex place-items-center px-2 z-50'>
                                        <img src={icon} className='w-5 h-5' />
                                        <input className='bg-[#09090B] rounded-lg py-1 px-3 flex gap-4 h-10 w-full' placeholder='Write Description' />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Cards */}
                    {/* This will be visible on large screens */}
                    <div className=' justify-center gap-4 mt-20  lg:flex hidden '>
                        {cards.map((card, index) => (
                            <div key={index} className={` h-auto w-[50%] hvr-float pricing rounded-lg p-3 ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                <div className="p-6 border-top mt-10">
                                    <h1 className='text-2xl font-bold mt-10'>{card.number}</h1>
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight mt-10">{card.title}</h5>
                                    <p className="font-normal">{card.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Swiper will be visible on medium and small screens */}
                    <div className='block lg:hidden mt-20 '>
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            pagination={Pagination}
                            loop={true}
                            breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                           
                            
                        }}
                        >
                            {cards.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <div className={`h-[35vh] rounded-lg xl:p-3 lg:p-0  ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                        <div className="p-3 border-top mt-10 ">
                                            <h1 className='text-2xl font-bold mt-5'>{card.number}</h1>
                                            <h5 className="mb-2 text-2xl font-bold  pt-5">{card.title}</h5>
                                            <p className="font-normal">{card.content}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Howitwork
