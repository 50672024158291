import React, { useContext, useState } from 'react';
import img1 from '../../assets/Images/Ellipse 130.png';
import img2 from '../../assets/Images/Ellipse 130 (1).png';
import img3 from '../../assets/Images/Ellipse 130 (2).png';
import img4 from '../../assets/Images/Ellipse 130 (3).png';
import img5 from '../../assets/Images/Ellipse 130 (4).png';
import img6 from '../../assets/Images/Ellipse 130 (5).png';
import img7 from '../../assets/Images/Ellipse 130 (6).png';
import img8 from '../../assets/Images/Ellipse 130 (7).png';
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import ThemeContext from '../../Component/ThemeContext';

const AIpersonalities = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };


    // Dynamic data for AI Personalities
    const aiPersonalitiesData = [
        {
            name: "Tech Savvy",
            description: "A tech-savvy AI, ideal for tech support, gadget recommendations...",
            imgSrc: img1,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Sage",
            description: "A wise and knowledgeable AI, perfect for deep discussions, a..",
            imgSrc: img2,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Friendly",
            description: "A friendly and empathetic AI, great for casual conversations, em..",
            imgSrc: img3,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Explorer",
            description: "An adventurous AI, perfect for travel advice, exploring new pla..",
            imgSrc: img4,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Muse",
            description: "A creative and imaginative AI, ideal for brainstorming, artist..",
            imgSrc: img5,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Navigator",
            description: "A practical and efficient AI, perfect for planning, organizing, and pr..",
            imgSrc: img6,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Health Guru",
            description: "A health-conscious AI, focused on fitness, nutrition, and welln..",
            imgSrc: img7,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        {
            name: "Scholar",
            description: "An academic and research-oriented AI, excellent for..",
            imgSrc: img8,
            traits: [
                "Curiosity and Open-Mindedness",
                "Ethical Reasoning",
                "Power",
                "Effective Communication"
            ]
        },
        // Add more AI personalities as needed
    ];

    return (
        <>
            <div className={`h-[100vh] ${isDarkMode ? "bg-[#09090B] text-white dashboard-bgg" : "bg-white text-black dashboard-bgggg"} flex flex-col`}>
                <div className="mb-5 mt-5 dashboard-bggg overflow-y-scroll h-[90vh]">
                    <div className=" p-8">
                        <div className='flex justify-between place-items-center'>
                            <div className=''>
                                <p className='mb-2'>Chat with AI</p>
                                <h1 className="text-4xl font-bold  font-color">AI Personalities</h1>
                                <p className="text-lg mb-6">
                                    Explore diverse AI personalities tailored to meet your unique needs, preferences, and interests.
                                </p>
                            </div>
                            <div className=''>
                                <Link to="/tryitnow">
                                <button className='all-btn rounded-lg px-4 py-2'>
                                    <span className='text-black font-bold'>Create New Chat</span>
                                </button>
                                </Link>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <h2 className="text-2xl font-bold mb-4">AI Characters</h2>
                            <div className="p-4 rounded-md">
                                <button
                                    onClick={toggleExpansion}
                                    className="text-gray-300 font-medium flex items-center justify-between w-full gap-4"
                                >
                                    Expand (0)
                                    <IoIosArrowDown className={`h-5 w-5 transform transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''}`} />
                                </button>

                                {/* Content to expand (initially hidden) */}
                                {isExpanded && (
                                    <div className="mt-4">
                                        {/* <p className="text-gray-400">This is the expanded content!</p> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                            {aiPersonalitiesData.map((personality, index) => (
                                <div key={index} className="glass rounded-lg shadow-md p-6 text-center h-[50vh] flex flex-col justify-between">
                                    <div className="flex items-center justify-center mb-4">
                                        <img
                                            src={personality.imgSrc}
                                            alt={personality.name}
                                            className="w-24 h-24 rounded-full"
                                        />
                                    </div>
                                    <h3 className="text-xl font-bold  mb-4">{personality.name}</h3>
                                    <p className="text-gray-400 text-sm mb-4">
                                        {personality.description}
                                    </p>
                                    <div>
                                        <span className="ai-allside px-2 py-2 rounded-md mr-2">
                                            {personality.traits[0]}
                                        </span>
                                        <div className="flex items-center mb-4 mt-4 justify-center">
                                            <span className="ai-allside px-2 py-2 rounded-md mr-2">
                                                {personality.traits[1]}
                                            </span>
                                            <span className="ai-allside px-2 py-2 rounded-md">
                                                {personality.traits[2]}
                                            </span>
                                        </div>
                                        <p className="ai-allside py-2 px-2 rounded-md mr-2">{personality.traits[3]}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AIpersonalities;
