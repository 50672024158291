import React, { useRef, useState, useContext } from 'react';
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import features1 from '../assets/Images/OurFeatures-1.jpeg';
import icon from '../assets/Images/fluent_prompt-32-filled.png';
import ThemeContext from './ThemeContext';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const Ourfeatures = () => {

    const { isDarkMode } = useContext(ThemeContext);


    const [data] = useState({
        title: "Our Features",
        subtitle: "Transform Workflow with AI",
        sections: [
            {
                title: "Design Unique Images Instantly",
                description: "Transform your text with our AI-powered sentence rewriter. Improve clarity, readability, and style in seconds. Perfect for creating engaging content, polished reports, and professional emails.",
                image: features1,
                buttonText: "Explore More"
            },
            {
                title: "Enhance Your Writing Effortlessly",
                description: "Transform your text with our AI-powered sentence rewriter. Improve clarity, readability, and style in seconds. Perfect for creating engaging content, polished reports, and professional emails.",
                buttonText: "Explore More"
            },
            {
                title: "Accelerate Your Development Process",
                description: "Write clean, efficient code quickly with our intelligent code generation tool. Ideal for developers looking to streamline their workflow and reduce errors.",
                buttonText: "Explore More"
            },
            {
                title: "Create Professional Templates Instantly",
                description: "Design customized templates for any document or project. Save time with our pre-designed, easily modifiable templates to ensure consistency and professionalism.",
                buttonText: "Explore More"
            }
        ],
        inputs: [
            {
                placeholder: 'Enter Your PRMPT',
                position: 'top-[-28rem] left-[47%] ',
                className: 'bg-[#27272A] rounded-lg py-1 px-3 flex gap-4 text-white',
            },

        ],
        textarea: [
            {
                placeholder: 'Write Description',
                position: 'top-[-8rem] left-[82%] xl:left-[74%]',
                height: 'h-20',
                className: 'bg-[#27272A] rounded-lg py-1 px-3 flex gap-4 resize-none place-items-center text-white',
                row: '4'
            }
        ],
        buttonText: "View All"
    });

    return (
        <div className={`py-10 lg:py-12 md:py-16 md:px-4 lg:px-12 xl:px-16 2xl:px-24 px-4  h-fit ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff] features-bgg' : 'bg-white text-black border-[black] features-bggg'}`}>
            <div className='features-bg'>
                <div className="container mx-auto text-center ">
                    <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold mb-2 sm:mb-3 md:mb-4 font-color">{data.title}</h2>
                    <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl font-bold mb-4 sm:mb-6 md:mb-8">{data.subtitle}</h1>
                </div>

                <div className={` rounded-lg p-4 sm:p-6 lg:flex gap-10 lg:gap-15 ${isDarkMode ? 'glass'  : ' glass1'}`}>
                    <div className="flex flex-col place-content-center">
                        <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4">{data.sections[0].title}</h3>
                        <p className="text-sm sm:text-lg mb-2 sm:mb-4">
                            {data.sections[0].description}
                        </p>
                        <button className="font-bold py-2 px-4 rounded-full flex gap-2 sm:gap-4 place-items-center text-2xl sm:text-3xl">
                            {data.sections[0].buttonText}
                            <BsArrowUpRightCircleFill className="text-base sm:text-lg"/>
                        </button>
                    </div>
                    <div className="w-full md:w-[100%] h-[200px] sm:h-[324px] md:h-[448px] lg:w-[100%] lg:h-[448px]">
                        <img
                            src={data.sections[0].image}
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>

                {data.inputs.map((input, index) => (
                    <div
                        key={index}
                        className={`hidden  xl:flex relative ${input.position} w-72 bg-[#27272A] rounded-lg place-items-center px-2 ${input.height || ''}`}
                    >
                        <img src={icon} className="w-5 h-5" alt="Icon" />
                        <input className={input.className} row={input.row} placeholder={input.placeholder} />
                    </div>
                ))}

                {data.textarea.map((textarea, index) => (
                    <div
                        key={index}
                        className={`hidden  xl:flex relative ${textarea.position} w-72 bg-[#27272A] rounded-lg place-items-center px-2 ${textarea.height || ''}`}
                    >
                        <img src={icon} className="w-5 h-5" alt="Icon" />
                        <textarea
                            name=""
                            id=""
                            className={textarea.className}
                            row={textarea.row}
                            placeholder={textarea.placeholder}
                        ></textarea>
                    </div>
                ))}

                <div>
                    {/* Grid for lg and above */}
                    <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 mt-4 lg:mt-5">
                        {data.sections.slice(1).map((section, index) => (
                            <div key={index} className={`hvr-float pricing rounded-2xl p-4 sm:p-6 ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-2 sm:mb-4">{section.title}</h3>
                                <p className="text-xs sm:text-sm mb-2 sm:mb-4">{section.description}</p>
                                <button className={`font-bold py-1 sm:py-2 px-2 sm:px-4 rounded-full flex gap-2 sm:gap-4  place-items-center text-lg sm:text-2xl hvr-grow`}>
                                    {section.buttonText}
                                    <BsArrowUpRightCircleFill className="text-base sm:text-lg" />
                                </button>
                            </div>
                        ))}
                    </div>

                    {/* Swiper for md and sm */}
                    <div className="lg:hidden mt-4 lg:mt-5">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            pagination={Pagination}
                            loop={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                            }}
                        >
                            {data.sections.slice(1).map((section, index) => (
                                <SwiperSlide key={index}>
                                    <div className={`rounded-2xl p-4 sm:p-6 h-[24vh] lg:h-[20vh] md:h-[32vh]  ${isDarkMode ? 'glass'  : ' glass1'}`}>
                                        <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-2 sm:mb-4">{section.title}</h3>
                                        <p className="text-xs sm:text-sm mb-2 sm:mb-4">{section.description}</p>
                                        <button className=" font-bold py-1 sm:py-2 px-2 sm:px-4 rounded-full flex gap-2 sm:gap-4 place-items-center text-lg sm:text-2xl hvr-grow">
                                            {section.buttonText}
                                            <BsArrowUpRightCircleFill className="text-base sm:text-lg" />
                                        </button>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                <div className="container mx-auto text-center mt-6 sm:mt-8">
                    <button className="text-black font-bold py-2 px-8 sm:px-10 rounded-lg all-btn hvr-bounce-in">
                        {data.buttonText}
                    </button>
                </div>
            </div>
        </div>

    );
}

export default Ourfeatures;
