import React, { useContext } from 'react'
import logo from '../assets/Images/chat-bot.png'
import facebook from '../assets/Images/facebook.png'
import instagram from '../assets/Images/instagram.png'
// import youtube from '../assets/Images/youtube.png'
import linkedin from '../assets/Images/linkedin.png'
import { FaXTwitter } from "react-icons/fa6";
import ThemeContext from './ThemeContext';
import { Link } from 'react-router-dom'



const Footer = () => {
    const { isDarkMode } = useContext(ThemeContext);

    const navItems = [
        { name: "Home", link: "/" },
        { name: "About", link: "/aboutus" },
        { name: "Features", link: "/features" },
        { name: "How To Use", link: "/Howtouse" },
        { name: "Pricing", link: "/pricing" },
    ];

    return (
        <>
            <div className={`bg-[#09090B] md:px-4 lg:px-16 px-4 ${isDarkMode ? 'bg-[#09090B] text-white border-[#ffffff]' : 'bg-white text-black border-[black] '}`}>
                <div className="lg:flex justify-between place-items-center">
                    <div className=" lg:w-1/2">
                        <div className='flex gap-4 place-items-center '>
                            <img src={logo} alt="Genius Logo" className="h-12" />
                            <h1 className="text-2xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl font-bold">Genius</h1>
                        </div>
                        <p className="text-lg mb-10">
                            At Genius, we are passionate about harnessing the power of
                            artificial intelligence to transform the way people create,
                            work, and innovate.
                        </p>
                    </div>
                    <div className="">
                        <ul className='flex justify-center flex-wrap space-x-5 md:space-x-8'>
                            {navItems.map((item, index) => (
                                <li key={index}>
                                    <a
                                        href={item.link}
                                        className={`hvr-grow block py-2 px-3 md:p-0 rounded text-xl  hover:text-[#B2567B] ${isDarkMode ? "text-white" : "text-black"
                                            }`}
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <footer className="  py-6 xl:px-16  border-t mt-5">
                    <div className="container-fluid mx-auto flex lg:flex-row  flex-col justify-center lg:justify-between items-center">
                        <p>Copyright © 2024 Genius. All rights reserved.</p>
                        <div className="flex space-x-4 my-5 lg:my-0 ">
                            <Link to='/privacypolicy' className="hvr-grow hover:text-[#B2567B]">
                                Privacy Policy
                            </Link>
                            <Link to='/helpcenter' className="hvr-grow hover:text-[#B2567B]">
                                Help Center
                            </Link>
                            <Link to='/security' className="hvr-grow hover:text-[#B2567B]">
                                Security
                            </Link>
                        </div>
                        <div className="flex space-x-4">
                            <a href="https://www.facebook.com/genixbit" className="hvr-grow">
                                <img src={facebook} className="w-8"/>
                            </a>
                            <a href="https://www.instagram.com/genixbit/" className="hvr-grow">
                                <img src={instagram} className="w-8"/>
                            </a>
                            <a href="https://x.com/genixbit" className="text-3xl hvr-grow">
                                <FaXTwitter />

                            </a>
                            <a href="https://www.linkedin.com/company/genixbit/" className="hvr-grow">
                                <img src={linkedin} className="w-8"/>
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer
