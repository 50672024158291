import React from 'react'
import Tryitnow from './Tryitnow'
import Footer from '../Component/Footer'

const Getstart = () => {
  return (
    <>
        <Tryitnow/>
        <Footer/>
    </>
  )
}

export default Getstart
