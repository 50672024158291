import React, { useState, useContext } from 'react';
import logo from '../../assets/Images/chat-bot.png';
import { MdModeEdit, MdSave } from "react-icons/md";
import ThemeContext from '../../Component/ThemeContext';

const Chathistory = () => {
    const { isDarkMode } = useContext(ThemeContext);
    const [editingMessageIndex, setEditingMessageIndex] = useState(null);
    const [editedText, setEditedText] = useState('');

    // Chat data stored in state for dynamic updates
    const [chatData, setChatData] = useState({
        Today: [
            { text: 'Tell me some UI/UX Design Ideas', time: '05:12 PM' },
            { text: 'Design Inspiration Resources', time: '05:15 PM' },
            { text: 'How do you approach designing for accessibility in UI?', time: '05:18 PM' }
        ],
        Yesterday: [
            { text: 'How to improve user engagement?', time: '04:00 PM' },
            { text: 'Tips for mobile-first design', time: '04:30 PM' },
            { text: 'What is responsive web design?', time: '05:00 PM' },
            { text: 'Best practices for web typography', time: '06:00 PM' }
        ],
        Previous: [
            { text: 'Principles of minimalist design', time: '03:12 PM' },
            { text: 'How to create a style guide?', time: '03:45 PM' },
            { text: 'User personas in UI design', time: '04:15 PM' },
            { text: 'UI/UX tools to use in 2024', time: '05:00 PM' },
            { text: 'Improving website performance', time: '05:30 PM' }
        ]
    });

    const handleEdit = (index, text, day) => {
        setEditingMessageIndex({ index, day });
        setEditedText(text);
    };

    const handleSave = (index, day) => {
        // Clone the chat data for immutability
        const updatedChats = { ...chatData };
        // Update the specific message in the correct day
        updatedChats[day][index].text = editedText;

        // Set the updated data back to state
        setChatData(updatedChats);
        setEditedText('');
        setEditingMessageIndex(null);
    };

    const renderChatSection = (dayLabel, chats, dayKey) => (
        <>
            <div className="flex items-center justify-center mt-5">
                <div className="text-sm font-medium">
                    <p>{dayLabel}</p>
                </div>
            </div>
            <div className="gap-4 p-3 rounded-lg flex items-center">
                                <div className="flex items-center">
                                    <img
                                        src={logo}
                                        alt="Genius"
                                        className="h-6 w-6 rounded-full mr-2"
                                    />
                                    <span className="font-medium">Genius</span>
                                </div>
                                <span className="text-xs text-gray-400">05:12 PM</span>
                            </div>
            {chats.map((message, index) => (
                <div key={index} className="glass p-3 rounded-lg flex items-center justify-between">
                    {editingMessageIndex?.index === index && editingMessageIndex?.day === dayKey ? (
                        <input
                            type="text"
                            value={editedText}
                            onChange={(e) => setEditedText(e.target.value)}
                            className="p-2 rounded-md flex-1"
                        />
                    ) : (
                        <span>{message.text}</span>
                    )}
                    {editingMessageIndex?.index === index && editingMessageIndex?.day === dayKey ? (
                        <MdSave onClick={() => handleSave(index, dayKey)} className="cursor-pointer" />
                    ) : (
                        <MdModeEdit onClick={() => handleEdit(index, message.text, dayKey)} className="cursor-pointer" />
                    )}
                </div>
            ))}
        </>
    );

    return (
        <div className={`h-[100vh] ${isDarkMode ? "bg-[#09090B] text-white dashboard-bgg" : "bg-white text-black dashboard-bgggg"} flex flex-col`}>
            <div className="mb-5 mt-5 dashboard-bggg overflow-y-scroll">
                <div className="p-8 rounded-lg  h-[85vh] ">
                    <h2 className="text-4xl font-bold mb-4 font-color">Chat History</h2>
                    <div className="flex flex-col gap-4 w-10/12 mx-auto">
                        {renderChatSection("Today", chatData.Today, "Today")}
                        {renderChatSection("Yesterday", chatData.Yesterday, "Yesterday")}
                        {renderChatSection("Previous 30 days", chatData.Previous, "Previous")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chathistory;
