import React, { useContext, useState, useEffect } from "react";
import "../assets/Css/Style.css";
import logo from "../assets/Images/chat-bot.png";
import { FaArrowAltCircleRight } from "react-icons/fa";
import ThemeContext from "./ThemeContext";
import { FaSun, FaMoon } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    // State to handle the mobile menu open/close
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };


    const navItems = [
        { name: "Home", link: "/" },
        { name: "About", link: "/aboutus" },
        { name: "Features", link: "/features" },
        { name: "How To Use", link: "/howtouse" },
        { name: "Pricing", link: "/pricing" },
    ];

    return (
        <>
            <nav className={`border-gray-200 md:mb-4 w-full px-6`}>
                <div className="flex items-center justify-between mx-auto pt-4 md:pb-4 md:px-16">
                    <Link
                    to='/'
                        className="flex items-center space-x-3 rtl:space-x-reverse hvr-grow "
                    >
                        <img src={logo} className="lg:h-16 h-10 " alt="Flowbite Logo" />
                        <span
                            className={`self-center lg:text-5xl md:text-3xl font-semibold whitespace-nowrap hover:text-[#B2567B] ${isDarkMode ? "text-white" : "text-black"
                                }`}
                        >
                            Genius
                        </span>
                    </Link>

                    <div
                        className={`items-center  justify-between w-full hidden xl:flex xl:w-auto `}
                        id="navbar-cta"
                    >
                        <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                            {navItems.map((item, index) => (
                                <li key={index}>
                                    <a
                                        href={item.link}
                                        className={`hvr-grow hvr-underline-from-center block py-2 px-3 md:p-0 rounded text-xl hover:text-[#B2567B] ${isDarkMode ? "text-white" : "text-black"
                                            }`}
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>

                    </div>
                    <div className="flex lg:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse gap-4 ">
                        <Link to='/tryitnow' className="hidden xl:flex">
                            <button
                                type="button"
                                className="  hvr-grow text-black all-btn focus:ring-0  focus:outline-none  font-bold rounded-lg text-lg px-4 py-2 text-center gap-2 place-items-center"
                            >
                                Try it Now <FaArrowAltCircleRight className="text-2xl" />
                            </button>
                        </Link>
                        <div className="xl:flex items-center hidden ">
                            {/* Toggle Switch */}
                            <label className="relative inline-flex items-center cursor-pointer">
                                {/* Hidden Checkbox */}
                                <input
                                    type="checkbox"
                                    className="sr-only"
                                    checked={isDarkMode}
                                    onChange={toggleTheme}
                                />

                                {/* Toggle Switch */}
                                <div
                                    className={`border w-[100px] h-11 rounded-full shadow-inner transition-colors duration-300 relative ${isDarkMode ? "bg-[#EFC502]" : "bg-[#2D2837]"
                                        }`}
                                >
                                    {/* Text inside the toggle background */}
                                    <span
                                        className={`absolute left-2 top-[8px] mx-1 text-base font-black  transition-colors duration-300 ${isDarkMode ? "text-[#FEF3BB]" : "hidden"
                                            }`}
                                    >
                                        Light
                                    </span>
                                    <span
                                        className={`absolute right-3 top-[8px] text-base font-black transition-colors duration-300 ${isDarkMode ? "hidden" : "text-[#736D81]"
                                            }`}
                                    >
                                        Dark
                                    </span>

                                    {/* Sliding part */}
                                    <div
                                        className={`flex justify-center items-center absolute left-[10px] top-[4px] w-8 h-8 bg-white rounded-full shadow-md transition-transform duration-300 transform ${isDarkMode ? "translate-x-[48px]" : "translate-x-0"
                                            }`}
                                    >
                                        {/* Icon inside the toggle */}
                                        {isDarkMode ? (
                                            <FaSun className="text-[#FDD835] w-6 h-6 m-auto" />
                                        ) : (
                                            <FaMoon className="text-black w-6 h-6 m-auto" />
                                        )}
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div className="lg:hidden block">
                            {/* Toggle Button: Hidden on xl and 2xl, visible on smaller screens */}
                            <button
                                onClick={handleMenuToggle}
                                type="button"
                                className=" items-center justify-center p-2 w-10 h-10 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 hvr-grow"
                                aria-controls="navbar-cta"
                                aria-expanded={isMenuOpen}

                            >
                                <span className="sr-only">Open main menu</span>
                                <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 17 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 1h15M1 7h15M1 13h15"
                                    />
                                </svg>
                            </button>

                            {/* Menu: Hidden by default on larger screens */}
                            <div
                                className={`z-20 fixed inset-x-0 top-0 ${isMenuOpen ? "block" : "hidden"
                                    } bg-white border-t border-gray-100 xl:hidden`}
                                id="navbar-cta"
                            >
                                <div className="flex justify-between  p-4 bg-black">
                                    {/* Close Button */}
                                    <div className="flex items-center">
                                        {/* Toggle Switch */}
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            {/* Hidden Checkbox */}
                                            <input
                                                type="checkbox"
                                                className="sr-only"
                                                checked={isDarkMode}
                                                onChange={toggleTheme}
                                            />

                                            {/* Toggle Switch */}
                                            <div
                                                className={` w-24 h-10 rounded-full shadow-inner transition-colors duration-300 relative ${isDarkMode ? "bg-[#EFC502]" : "bg-[#2D2837]"
                                                    }`}
                                            >
                                                {/* Text inside the toggle background */}
                                                <span
                                                    className={`absolute left-2 top-[8px] mx-1 text-base font-black  transition-colors duration-300 ${isDarkMode ? "text-[#FEF3BB]" : "hidden"
                                                        }`}
                                                >
                                                    Light
                                                </span>
                                                <span
                                                    className={`absolute right-3 top-2 text-base font-black transition-colors duration-300 ${isDarkMode ? "hidden" : "text-[#736D81]"
                                                        }`}
                                                >
                                                    Dark
                                                </span>

                                                {/* Sliding part */}
                                                <div
                                                    className={`flex justify-center items-center absolute left-[10px] top-[4px] w-8 h-8 bg-white rounded-full shadow-md transition-transform duration-300 transform ${isDarkMode ? "translate-x-[48px]" : "translate-x-0"
                                                        }`}
                                                >
                                                    {/* Icon inside the toggle */}
                                                    {isDarkMode ? (
                                                        <FaSun className="text-[#FDD835] w-6 h-6 m-auto" />
                                                    ) : (
                                                        <FaMoon className="text-black w-6 h-6 m-auto" />
                                                    )}
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <button
                                        onClick={handleCloseMenu}
                                        type="button"
                                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white hvr-grow"
                                        aria-label="Close menu"
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 14"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2 2l10 10M2 12L12 2"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                <ul className="flex justify-center flex-col font-medium p-4 space-y-4 md:space-x-8 rtl:space-x-reverse md:flex-row md:space-y-0 md:p-0 md:border-0 bg-black text-center ">
                                    {navItems.map((item, index) => (
                                        <li key={index}>
                                            <a
                                                href={item.link}
                                                className={`hvr-grow hvr-underline-from-center block py-2 px-3 md:p-0 rounded text-xl hover:text-[#B2567B] ${isDarkMode ? "" : "text-white"
                                                    }`}
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                <Link to='/tryitnow'>
                                    <button
                                        type="button"
                                        className="text-black all-btn focus:ring-0 focus:outline-none font-bold rounded-lg text-lg px-4 py-2 w-full  text-center flex gap-2 place-items-center justify-center hvr-grow"
                                    >
                                        Try it Now <FaArrowAltCircleRight className="text-2xl" />
                                    </button>
                                </Link>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Mobile Menu */}
                </div>
            </nav>
        </>
    );
};

export default Navbar;
